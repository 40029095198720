import {API} from '../axios-config';
import {SPONSOR_ID} from "@/config";
import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";


export default {
    state: {
        games: [],
        sponsorGames: []
    },
    mutations: {
        SET_GAMES_TO_STATE: (state, games) => {
            state.games = games;
        },
        SET_SPONSOR_GAMES_TO_STATE: (state, games) => {
            state.sponsorGames = games;
        },
    },
    actions: {
        async GET_DISCIPLINES({commit}) {
            const res = await API({
                url: `/games?include=logo`,
                method: 'GET',
            });

            let games = res.data.data;
            let logos = includeFilteringByTag(res.data.included, 'logo')

            games.map(game => {
                game.logo = logos.find(logo => logo.id === game.relationships.logo.data.id).attributes.url
            })

            commit('SET_GAMES_TO_STATE', games)
        },
        async GET_SPONSOR_DISCIPLINES({commit}) {
            const response = await API({
                method: 'get',
                url: `/groups-of-tournaments?filter[sponsor_id]=${SPONSOR_ID}&fields[groups-of-tournaments]=id&include=game.logo`
            })
            const included = response.data.included
            const games = includeFilteringByType(included, 'games')
            const logos = includeFilteringByTag(included, 'logo')
            games.map(game => {
                game.logo = logos.find(el => String(el.id) === String(game.relationships.logo.data.id)).attributes.url
            })
            commit('SET_SPONSOR_GAMES_TO_STATE', games)
        }
    },
    getters: {
        GAMES(state) {
            return state.games;
        },
        GET_SPONSOR_GAMES(state) {
            return state.sponsorGames;
        },
    },
}
