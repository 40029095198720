export const isPhoneOrMail = (str) => {
    const mailRegEx = /(.*@.*\.[a-z]+)/gm;
    const phoneRegEx = /(\+7\d{10})/gm;

    if (phoneRegEx.test(str)) {
        return 'phone'
    }
    if (mailRegEx.test(str)) {
        return 'email'
    }

    return null
}