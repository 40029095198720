import {API} from "@/Vuex/axios-config";
import {SPONSOR_ID} from "@/config";
import {attachmentsGetFromInclude} from "@/otherJS/include-filtering";


export default {
    state: {
        news_meta: {},
        news: [],
        news_config: {},
    },

    actions: {
        async FETCH_LATEST_NEWS({commit}, payload) {
            const response = await API({
                url: `/news?sort=sort,-createdAt&filter[sponsor_id]=${SPONSOR_ID}&include=preview&page[number]=${payload.page_number}&page[size]=${payload.page_size}`,
                method: "GET"
            })
            const latestNews = response.data.data

            latestNews.map(el => {
                el.logo = attachmentsGetFromInclude(el, response.data.included, 'preview')?.attributes?.url ?? ''
            })
            return latestNews
        },
        async FETCH_ALL_NEWS({commit, getters}, payload) {

            commit('SET_NEWS_CONFIG', payload)

            const response = await API({
                url: `/news?filter[sponsor_id]=${SPONSOR_ID}&page[number]=${payload.page_number}&page[size]=${payload.page_size}${payload.filter}${payload.sort}&filter[search]=${payload.searchText}&include=preview`,
                method: "GET"
            })

            commit('SET_NEWS_META', response?.data?.meta?.page);

            const allNews = response?.data?.data

            allNews?.map(el => {
                el.logo = attachmentsGetFromInclude(el, response.data.included, 'preview')?.attributes?.url ?? ''
            })


            commit('SET_NEWS', allNews)
        },
        async FETCH_MORE_NEWS({commit, getters}) {

            const payload = getters.GET_NEWS_CONFIG
            const meta = getters.GET_NEWS_META

            const nextPage = meta['current-page'] + 1

            const response = await API({
                url: `/news?filter[sponsor_id]=${SPONSOR_ID}&page[number]=${nextPage}&page[size]=${payload.page_size}${payload.filter}${payload.sort}&include=preview`,
                method: "GET"
            })

            if (meta.page_number === 1) commit('CLEAR_NEWS')

            commit('SET_NEWS_META', response?.data?.meta?.page);

            const allNews = response?.data?.data

            allNews?.map(el => {
                el.logo = attachmentsGetFromInclude(el, response.data.included, 'preview')?.attributes?.url ?? ''
            })

            commit('SET_NEWS', [...getters.GET_NEWS, ...allNews])
        },
        async FETCH_SINGLE_NEWS({commit, getters}, news_id) {
            const response = await API({
                url: `/news/${news_id}?include=newsable,preview,game,comments,comments.user,comments.user.logo`,
                method: "GET"
            })
            let singleNews = response.data.data
            const relationships = response.data.data.relationships
            let attachments = response.data.included.filter(item => item.type === 'attachments')
            let comments = response.data.included.filter(item => item.type === 'comments')

            singleNews.relation = response.data.included.find(item => item.type === 'tournaments') ?? response.data.included.find(item => item.type === 'groups-of-tournaments') ?? ''

            for (let comment of comments) {
                comment.author = response.data.included.find(item => item.type === 'users' && item.id === comment.relationships.user.data.id) ?? ''
                comment.author.avatar = attachments.find(item => item.type === 'attachments' && item.id === comment.author.relationships.logo.data.id)?.attributes?.url ?? ''
            }

            comments?.sort((a, b) => new Date(b.attributes.createdAt).valueOf() - new Date(a.attributes.createdAt))

            singleNews.comments = comments

            return singleNews
        },

        async POST_COMMENT(ctx, payload) {
            const response = await API({
                url: `/comments`,
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    data: {
                        type: 'comments',
                        attributes: {
                            message: payload.message
                        },
                        relationships: {
                            commentable: {
                                data: {
                                    type: 'news',
                                    id: payload.news_id
                                }
                            },
                            user: {
                                data: {
                                    type: 'users',
                                    id: payload.user_id
                                }
                            }
                        }
                    }
                }
            })

        }
    },

    mutations: {
        SET_NEWS_META(state, meta) {
            state.news_meta = meta;
        },
        SET_NEWS_CONFIG(state, config) {
            state.news_config = config
        },
        SET_NEWS(state, news) {
            state.news = news
        },
        CLEAR_NEWS(state) {
            state.news = []
        },
    },

    getters: {
        GET_NEWS(state) {
            return state.news
        },
        GET_NEWS_META(state) {
            return state.news_meta
        },
        GET_NEWS_CONFIG(state) {
            return state.news_config
        },
    }
}
