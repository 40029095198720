<template>
  <div class="navigation-panel">
    <div class="container">
      <nav class="navigation-panel__wrapper">
        <ul>
          <li>
            <router-link exact-active-class="active" :to="{name: 'user'}">Профиль</router-link>
          </li>
          <li>
            <router-link exact-active-class="active" :to="{name: 'user.security'}">Безопасность</router-link>
          </li>
          <!--<li>
            <router-link exact-active-class="active" :to="{name: 'user.links'}">Связать с аккаунтом</router-link>
          </li>-->
          <!--<li>
            <router-link exact-active-class="active" to="/user/notifications">Уведомления</router-link>
          </li>
          <li>
            <router-link exact-active-class="active" to="/user/friends">Друзья</router-link>
          </li>
          <li>
            <router-link exact-active-class="active" to="/">Рейтинг и награды</router-link>
          </li>
          <li>
            <router-link exact-active-class="active" to="/">Статистика</router-link>
          </li>-->
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: "profileNav",
  mounted() {

  }
}
</script>

<style lang="scss" scoped>

</style>