<template>
  <router-link :to="{name: 'team.name', params: {id: team.id}}" @click.native="goToTeamProfile" class="teamCard">
    <div class="teamCard__left left">
      <div class="left__img">
        <img :src="team.logoUrl" alt="not found">
      </div>

      <div class="left__block leftBlock">
        <span class="leftBlock__firstTitle">{{ team.attributes.name }}</span>
        <span class="leftBlock__secondTitle">{{ getCountPlayersInTeam }} участников</span>
      </div>
    </div>
    <div class="teamCard__right right">
      <div class="right__iconContainer">
        <country-flag :country="team.attributes.country" rounded size="normal"/>
      </div>
    </div>
  </router-link>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import {mapActions} from "vuex";

export default {
  name: "searchTeamBlock",
  props: ['team'],
  components: {CountryFlag},
  methods: {
    ...mapActions([
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN',
      'SET_STATUS_MOBILE_NAV'
    ]),
    goToTeamProfile() {
      this.SET_STATUS_MOBILE_NAV(false);
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
    }
  },
  computed: {
    getCountPlayersInTeam() {
      return this.team.relationships.users.meta.roles.length
    },
  }
}
</script>

<style lang="scss" scoped>
.teamCard {
  margin-top: 8px;
  padding: 4px 8px;
  background-color: #3A3A3A;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__left, .left {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__img {
      width: 28px;
      height: 28px;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__block, .leftBlock {
      margin-left: 8px;
      display: flex;
      flex-direction: column;

      &__firstTitle {
        font-family: 'SF UI Text', serif;
        font-size: 14px;
        line-height: 17px;
        color: white;
      }

      &__secondTitle {
        font-family: 'SF UI Text', serif;
        font-size: 12px;
        line-height: 14px;
        color: white;
        opacity: .6;
      }
    }
  }

  &__right, .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__iconContainer {

    }
  }

  &:hover {
    background-color: lighten(#3A3A3A, 10);
  }
}
</style>
