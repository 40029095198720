<template>
    <div class="playersBlock" v-if="getIsHaveData">
      <header-block :headerBlock="headerBlock" />
      <search-player-block v-for="player of GET_RESPONSE_USERS_DROPDOWN.USERS" :key="player.id" :player="player" />
    </div>
</template>

<script>
import SearchPlayerBlock from "@/components/searchDropDown/searchPlayersBlock/searchPlayerBlock/searchPlayerBlock";
import HeaderBlock from "@/components/searchDropDown/components/headerBlock/headerBlock";
import {mapGetters} from "vuex";

export default {
  name: "searchPlayersBlock",
  components: {HeaderBlock, SearchPlayerBlock},
  computed: {
    ...mapGetters([
        'GET_RESPONSE_USERS_DROPDOWN'
    ]),

    headerBlock() {
      return {
        type: 'users',
        count: this.GET_RESPONSE_USERS_DROPDOWN.COUNT
      }
    },

    getDataIsArray() {
      return Array.isArray(this.GET_RESPONSE_USERS_DROPDOWN.USERS)
    },

    getIsHaveData() {
      return this.getDataIsArray && this.GET_RESPONSE_USERS_DROPDOWN.USERS.length !== 0
    }
  },
}
</script>

<style lang="scss" scoped>
.playersBlock {
  margin-top: 24px;
  padding-top: 24.5px;
  padding-bottom: 16px;
  border-top: rgba(255, 255, 255, .4) 1px solid;
}
</style>