import {API} from "@/Vuex/axios-config";

const setRatingTournamentGroups = async (tournamentGroupsId) => {
    try {
        // Получаю рейтинги по группе турнира
        const response = await API({
            method: 'get',
            url: `/groups-of-tournaments/${tournamentGroupsId}?include=ratings&fields[groups-of-tournaments]=game_id`
        })

        // Собираю массив рейтинга по группе турнира
        const arrayRating = response.data.included[0].relationships.participantables.meta;

        // Проверяю (на всякий случай), чтобы у участников рейтинга был тип "users"
        const isRatingForUsers = arrayRating.every((item) => item.type === 'users');

        // Если все участники - это люди (не команды)
        if (isRatingForUsers) {
            // Собираю id пользователей
            const usersId = arrayRating.map((item) => item.id).join(',')

            // Получаю информацию по id пользователям. Мне нужны только логин, фамилия и имя
            const responseUsers = await API({
                method: 'get',
                url: `/users?fields[users]=login,firstName,lastName&filter[id]=${usersId}`
            })

            // Объединяю массив с рейтингами с новой информацией о пользователях
            const newArrayRating = arrayRating.map((rate) => {
                const responseAboutUsers = responseUsers.data.data.find((user) => String(user.id) === String(rate.id));

                rate.login = responseAboutUsers.attributes.login;
                rate.firstName = responseAboutUsers.attributes.firstName;
                rate.lastName = responseAboutUsers.attributes.lastName;

                return rate;
            })

            return {
                data: response.data.data,
                rating: newArrayRating
            }
        }


    } catch (e) {
        console.log('SET_RATING_MAIN_TOURNAMENT_GROUPS', e);
        throw new Error(`SET_RATING_MAIN_TOURNAMENT_GROUPS ${e}`)
    }
}

export default setRatingTournamentGroups