import {SPONSOR_ID} from "@/config";

import getSponsorInfoForMainPage from "@/otherJS/sponsorInfo/getSponsorInfoForMainPage";
import getSponsorInfoForEventGroups from "@/otherJS/sponsorInfo/getSponsorInfoForEventGroups";
import BannersImages from "@/otherJS/sponsorInfo/BannersImages";
import {API} from "@/Vuex/axios-config";
import {includeFilteringByTag} from "@/otherJS/include-filtering";

export default {
    state: {
        sponsorInfo: null,
        currentSponsor: {}
    },

    actions: {
        async SET_SPONSOR_INFO({commit}, payload) {
            try {
                let response;

                if (payload === 'main') response = await getSponsorInfoForMainPage(SPONSOR_ID);
                else if (payload === 'eventGroups') response = await getSponsorInfoForEventGroups(SPONSOR_ID);

                commit('SET_SPONSOR_INFO', response);
            } catch (e) {
                console.log('SET_SPONSOR_INFO', e);
                throw new Error(`SET_SPONSOR_INFO ${e}`)
            }
        },
        async GET_SPONSOR_DATA({commit}) {
            const response = await API({
                method: 'get',
                url: `/sponsors/${SPONSOR_ID}?include=logo`
            });

            const data = response.data.data;

            const sponsor = data;
            const logos = includeFilteringByTag(response.data.included, 'logo');

            sponsor.logo = logos.find(item => item.id === data.relationships.logo.data.id).attributes.url;

            commit('SET_SPONSOR_DATA', sponsor);
        }
    },

    mutations: {
        SET_SPONSOR_INFO(state, payload) {
            state.sponsorInfo = payload
        },
        SET_SPONSOR_DATA(state, data) {
            state.currentSponsor = data;
        }
    },

    getters: {
        SPONSOR(state) {
            return state.currentSponsor;
        },
        GET_SPONSOR_BANNERS_FOR_MIDDLE_MAIN(state) {
            try {
                const banners = new BannersImages(state.sponsorInfo.included, 'sponsor_main_middle_banner');
                return banners.getBanners
            } catch (e) {
                return null
            }

        },

        GET_SPONSOR_BANNERS_FOR_BOTTOM_MAIN(state) {
            try {
                const banners = new BannersImages(state.sponsorInfo.included, 'sponsor_main_bottom_banner');
                return banners.getBanners
            } catch (e) {
                return null
            }
        },

        GET_SPONSOR_BANNERS_FOR_MIDDLE_EVENT_GROUPS(state) {
            try {
                const banners = new BannersImages(state.sponsorInfo.included, 'sponsor_group_middle_banner');
                return banners.getBanners
            } catch (e) {
                return null
            }
        }
    },
}