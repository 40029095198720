<template>
  <div id="app">
    <div class="app_bg">
      <!--      <img src="@/img/main_bg.jpg" alt="#">-->
    </div>

    <header-main class="app-notification-modal"></header-main>

    <invite-to-team/>

    <transition name="warningModalTransition">
      <div v-if="this.GET_WARNING_MODAL_STATUS">
        <warning-modal/>
      </div>
    </transition>

    <div v-if="this.GET_UNIVERSAL_MODAL_STATUS">
      <universal-modal/>
    </div>

    <transition name="default">
      <component :is="layout">
        <router-view></router-view>
      </component>
    </transition>

    <simple-text-modal v-if="GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN" />

    <footer-component/>
  </div>
</template>

<script>
import HeaderMain from "@/components/headerMain";
import {mapActions, mapGetters, mapMutations} from "vuex";
import InviteToTeam from "@/components/modals/inviteToTeam/inviteToTeam";
import WarningModal from "@/components/modals/warning-modal";
import UniversalModal from "@/components/modals/universal-modal";
import {onLoadListener, destroyLoadListener} from "@/otherJS/listeners/onLoadListener";
import FooterComponent from "@/components/footer/footerComponent";
import {ActiveEcho} from "@/main";
import notificationSound from "@/sounds/notification.mp3";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";
import SimpleTextModal from "@/components/modals/simple-text-modal";

export default {
  name: 'App',
  components: {SimpleTextModal, FooterComponent, UniversalModal, WarningModal, InviteToTeam, HeaderMain},
  mixins: [tournamentInfoMixin],
  computed: {
    ...mapGetters([
      'GET_UNIVERSAL_MODAL_STATUS',
      'GET_WARNING_MODAL_STATUS',
      'GET_USER_IS_AUTHENTICATE',
      'GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN'
    ]),
    layout() {
      return (this.$route.meta.layout || "container") + '-layout';
    }
  },
  methods: {
    ...mapActions([
      'REFRESH_USER_TOKEN',
      'GET_SECRET',
      'GET_DISCIPLINES',
      'GET_SPONSOR_DISCIPLINES',
      // 'SET_SPONSOR_GAMES',
      'SET_WEB_SOCKETS_NEW_NOTIFICATION',
      'GET_SPONSOR_DATA'
    ]),
    ...mapMutations([
        'SET_IS_REQS_WITH_USER'
    ])
  },
  async created() {
    await this.GET_SECRET();
    await this.GET_DISCIPLINES();
    await this.GET_SPONSOR_DISCIPLINES();

    let refreshToken = localStorage.getItem('refreshAccessToken');
    if (refreshToken !== null) {
      await this.REFRESH_USER_TOKEN();
    }
    this.SET_IS_REQS_WITH_USER(true);
  },
  async mounted() {
    await this.GET_SPONSOR_DATA();
    onLoadListener();
  },

  destroyed() {
    destroyLoadListener();
  },

  watch: {
    GET_USER_IS_AUTHENTICATE() {
      // Если на бэкенде есть сокеты, то нужно раскоментировать код

      if (this.GET_USER_IS_AUTHENTICATE) {

        // Блок кода, отвечающий за подключение к сокетам уведомления
        const userId = JSON.parse(localStorage.getItem('user')).data.id;
        console.log('socket on')

        ActiveEcho.private('users.' + userId)
            .notification((notification) => {

              localStorage.setItem('newNotification', 'true');
              this.SET_WEB_SOCKETS_NEW_NOTIFICATION(true)

              const audio = new Audio(notificationSound);
              audio.play();
            });

        // Блок кода, отвечающий за регистрацию на турнире,
        // если пользователь нажал на кнопку "принять участие"
        // будучи неавторизованным

        this.registerUserInTournamentAfterAuth();
      }
    }
  }
}

</script>

<style lang="scss">
@import "./src/scss/main";


.warningModalTransition {
  &-enter-active, &-leave-active {
    transition: opacity 1s ease;
  }

  &-enter, &-leave-to {
    .warningModalContainer {
      opacity: 0;
    }
  }
}

#app {
  min-height: 100vh;
  position: relative;
  padding-bottom: 327px;
  @media (max-width: 525px) {
    padding-bottom: 500px;
  }
}

.app_bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  filter: blur(8px);

  background-color: #272727;

  /*
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(#242424, .9);
      z-index: 1;
    }
  */

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}

</style>

