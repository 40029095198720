
// Computed, который в Data будет вставлять необходимые поля из included, по типу
// Ссылка на аватарку, название команды и т.п.
const getNotificationsWithIncludedInfo = (notifications) => {
    try {
        // Data с уведомлениями
        const noteData = [...notifications.data];

        if (!notifications?.included) {
            return noteData
        }

        // included уведомлений
        const noteIncluded = [...notifications.included];

        let tempData = []

        // захожу в data уведомления
        for (let notificationData of noteData) {
            // Если тип уведомления = User.Entries.TeamToUser, то прохожу по include
            if (notificationData.attributes['type_notification'] === 'User.Entries.TeamToUser') {

                const includedTeam = noteIncluded.find(item =>
                    item.type === 'teams'
                    && String(notificationData.relationships.initiator.data.id) === String(item.id))

                notificationData.teamName = includedTeam.attributes.name
                notificationData.teamTag = includedTeam.attributes.tag
                notificationData.teamLogoUrl = includedTeam.attributes.logo

                tempData = [...tempData, notificationData]

            } else tempData = [...tempData, notificationData]
        }

        return tempData;
    } catch (e) {
        return [...notifications.data]
    }
}

export default getNotificationsWithIncludedInfo