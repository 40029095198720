<template>
  <div class="sgl-footer--socials" v-show="socialLinks">
    <span>Мы в соцсетях:</span>
    <div class="icons">
      <a :href="link.attributes.link" target="_blank" v-for="link in socialLinks" :key="link.id">
        <img :src="link.logo" alt="?">
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: "footerComponent-socials",
  data() {
    return {}
  },
  props: {
    socialLinks: Array
  },
  methods: {},
  mounted() {
  },
}
</script>

<style scoped>

</style>