import {API} from '../axios-config';
import {SPONSOR_ID} from "@/config";


export default {
    state: {
        gamesRecordings: [],
        activeStream: '',
        recordsMeta: {
            page: {
                total: 0
            }
        },
        isRecordsInProgress: false,
    },
    mutations: {
        SET_RECORDINGS: (state, recordings) => {
            state.gamesRecordings.length
                ?
                state.gamesRecordings = state.gamesRecordings.concat(recordings)
                :
                state.gamesRecordings = recordings
        },
        CLEAR_RECORDS: (state) => {
            state.gamesRecordings = []
        },
        SET_RECORDINGS_META: (state, meta) => {
            state.recordsMeta = meta
        },
        SET_RECORDINGS_PROGRESS: (state, progress) => {
            state.isRecordsInProgress = progress
        }
    },
    actions: {
        async GET_RECORDINGS({commit}, dataToGetRecordings) {
            commit('SET_RECORDINGS_PROGRESS', true)

            let url = `/game-recordings?filter[sponsor_id]=${SPONSOR_ID}&page[number]=${dataToGetRecordings.page_number}&page[size]=${dataToGetRecordings.page_size}${dataToGetRecordings.filter}${dataToGetRecordings.sort}`
            const res = await API({
                url,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
            });

            const recordings = res.data.data
                .map(record => {
                    const recordPath = new URL(record.attributes.url)
                    record.attributes.record_path = recordPath.pathname
                    return record;
                });
            const meta = res.data.meta;

            if (dataToGetRecordings.page_number === 1) commit('CLEAR_RECORDS')
            commit('SET_RECORDINGS', recordings)
            commit('SET_RECORDINGS_META', meta)
            commit('SET_RECORDINGS_PROGRESS', false)
        },
        async GET_ACTIVE_STREAM() {
            const res = await API({
                url: `/broadcast-tournament/${SPONSOR_ID}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
            });

            return res
        }
    },
    getters: {
        GAMES_RECORDINGS(state) {
            return state.gamesRecordings;
        },
        GET_RECORDS_META(state) {
            return state.recordsMeta;
        },
        IS_RECORDS_IN_PROGRESS(state) {
            return state.isRecordsInProgress;
        }
    },
}
