import store from '../../Vuex/store'
import NBA from "@/components/tournaments/singleTournament/brackets/groupstage/GroupsByGames/NBA/NBA";
import NHL from "@/components/tournaments/singleTournament/brackets/groupstage/GroupsByGames/NHL/NHL";
import UFC from "@/components/tournaments/singleTournament/brackets/groupstage/GroupsByGames/UFC/UFC";
import Fifa from "@/components/tournaments/singleTournament/brackets/groupstage/GroupsByGames/Fifa/Fifa";

export default class GetGroupWinners {
    constructor(groupMatches) {
        this.group = groupMatches;
        this.participants = this.getGroupParticipants();
    }

    getWinners() {
        let winners = this.getWinnersFromParticipants();
        winners = this.addParticipantsAttrs(winners);

        return winners;
    }

    getGroupParticipants() {

        switch (store.getters.TOURNAMENT.attributes.sort_after_group) {
            case 'NBA':
                return (new NBA(this.group)).getSortedParticipants();
            case 'NHL':
                return (new NHL(this.group)).getSortedParticipants();
            case 'UFC':
                return (new UFC(this.group)).getSortedParticipants();
            default:
                return (new Fifa(this.group)).getSortedParticipants();
        }

    }

    addParticipantsAttrs(participants) {
        for (let i = 0; i < participants.length; i++) {
            let participant = participants[i];
            for (let j = 0; j < store.getters.PARTICIPANTS.length; j++) {
                let stateParticipant = store.getters.PARTICIPANTS[j];
                if (participant.id === Number(stateParticipant.id)) {
                    participant.attributes = stateParticipant.attributes;

                    break;
                }
            }
        }

        return participants;
    }

    getWinnersFromParticipants() {
        return this.participants.slice(0, 3);
    }
}
