import {API} from "@/Vuex/axios-config";

const acceptInviteToTeam = async (notification_id) => {

    const accessToken = localStorage.getItem('userAccessToken');

    try {
        const response = await API({
            method: 'post',
            url: `/notifications/${notification_id}/change`,
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            data: {
                "event": "confirm"
            }
        })

        return response.data;
    } catch (e) {
        console.log(`acceptInviteToTeam ${e}`);
        throw new Error(`acceptInviteToTeam ${e}`);
    }
}

export default acceptInviteToTeam;