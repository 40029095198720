<template>
  <div class="tournamentsBlock" v-if="getIsHaveData">
    <header-block :headerBlock="headerBlock" />
    <search-tournament-block v-for="tournament of GET_RESPONSE_TOURNAMENTS_DROPDOWN.TOURNAMENTS" :key="tournament.id" :tournament="tournament" />
  </div>
</template>

<script>
import HeaderBlock from "@/components/searchDropDown/components/headerBlock/headerBlock";
import SearchTournamentBlock
  from "@/components/searchDropDown/searchTournamentsBlock/searchTournamentBlock/searchTournamentBlock";
import {mapGetters} from "vuex";
export default {
  name: "searchTournamentsBlock",
  components: {SearchTournamentBlock, HeaderBlock},
  computed: {
    ...mapGetters([
        'GET_RESPONSE_TOURNAMENTS_DROPDOWN',
    ]),

    headerBlock() {
      return {
        type: 'tournaments',
        count: this.GET_RESPONSE_TOURNAMENTS_DROPDOWN.COUNT
      }
    },

    getDataIsArray() {
      return Array.isArray(this.GET_RESPONSE_TOURNAMENTS_DROPDOWN.TOURNAMENTS)
    },

    getIsHaveData() {
      return this.getDataIsArray && this.GET_RESPONSE_TOURNAMENTS_DROPDOWN.TOURNAMENTS.length !== 0
    }
  },
}
</script>

<style lang="scss" scoped>
.tournamentsBlock {
  margin-top: 16.5px;
  padding-top: 24.5px;
  padding-bottom: 16px;

  border-top: rgba(255, 255, 255, .4) 1px solid;
  // border-bottom: rgba(255, 255, 255, .4) 1px solid;
}
</style>