<template>
  <router-link :to="{name: 'tournaments_id', params: {id: tournament.id}}" @click.native="goToTournament" class="tournamentBlock">
    <div class="tournamentBlock__left left">
      <span class="left__firstSpan">{{ tournament.attributes.name }}</span>

      <div class="spans">
        <span class="left__secondSpan">{{ getStatus }}</span>
        <span class="left__thirdSpan">{{ parseDate }}</span>
      </div>

    </div>
    <div class="tournamentBlock__right right">
      <div class="right__icon">
        <country-flag :country="tournament.attributes.country" rounded size="normal"/>
      </div>
    </div>
  </router-link>
</template>

<script>
import CountryFlag from "vue-country-flag";
import getMonthName from '@/otherJS/getMonthName';
import {mapActions} from "vuex";

export default {
  name: "searchTournamentBlock",
  props: ['tournament'],
  components: {CountryFlag},
  methods: {
    ...mapActions([
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN',
      'SET_STATUS_MOBILE_NAV'
    ]),
    goToTournament() {
      this.SET_STATUS_MOBILE_NAV(false);
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
    }
  },
  computed: {
    parseDate() {
      const date = this.getDateFromStatus;

      const day = date.getDate();
      const month = getMonthName(date.getMonth()).slice(0, 3);
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    },

    getDateFromStatus() {
      const status = this.tournament.attributes.status;

      switch (status) {
        case 'in_process':
          return new Date(this.tournament.attributes['started_at'])
        default:
          return new Date()
      }
    },

    getStatus() {
      const status = this.tournament.attributes.status;

      switch (status) {
        case 'in_process':
          return 'Начался'
        default:
          return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .tournamentBlock {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    background-color: #3A3A3A;
    margin-top: 8px;
    padding: 10px 8px;
    flex-direction: row;
    align-items: center;

    &__left, .left {
      flex-direction: column;
      display: flex;

      &__firstSpan {
        color: white;
        font-size: 14px;
        font-family: 'SF UI Text', serif;
        line-height: 16.8px;
        font-weight: normal;
      }

      .spans {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__secondSpan {
        color: white;
        font-size: 12px;
        font-family: 'SF UI Text', serif;
        line-height: 14.4px;
        font-weight: normal;
        opacity: .6;
        text-transform: uppercase;
      }

      &__thirdSpan {
        margin-left: 4px;
        color: white;
        font-size: 12px;
        font-family: 'SF UI Text', serif;
        line-height: 14.4px;
        font-weight: normal;
      }
    }

    &__right, .right {
      &__icon {

      }
    }

    &:hover {
      background-color: lighten(#3A3A3A, 10);
    }
  }
</style>