<template>
  <div v-if="notificationIsLoaded" class="container">
    <div class="avatar">
      <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="avatar__img">
        <circle cx="17" cy="16" r="16" fill="url(#paint0_linear)"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M23.8675 15.9447V17.3526H26.0281C25.6183 18.1739 24.7976 18.6495 23.4497 18.6565H10.6097C8.54149 18.6565 7.67473 17.5886 7.67473 15.8167C7.67473 14.0449 8.54141 12.9776 10.6097 12.9776H23.4472C25.6882 12.9856 26.5536 13.925 26.5536 13.925L27.6775 12.9871C27.6775 12.9871 26.4112 11.517 23.4527 11.5049C23.4413 11.5054 10.6221 11.5054 10.6102 11.5054C7.8304 11.5054 6.02734 13.4355 6.02734 15.8173C6.02734 18.1992 7.8304 20.1293 10.6102 20.1293H23.4476C24.6338 20.1234 25.6394 19.7642 26.4016 19.17V20.0004H28.0009V15.9447H23.8675Z"
              fill="#E2FF31"/>
        <path
            d="M14.7518 13.537L15.8833 14.6684L14.7369 15.8148L15.8833 16.9611L14.7518 18.0926L13.6055 16.9463L12.4591 18.0926L11.3276 16.9612L12.474 15.8148L11.3277 14.6684L12.4591 13.537L13.6055 14.6833L14.7518 13.537Z"
            fill="#E2FF31"/>
        <path
            d="M20.9432 13.537L22.0747 14.6684L20.9283 15.8148L22.0747 16.9611L20.9432 18.0926L19.7969 16.9463L18.6505 18.0926L17.5191 16.9612L18.6654 15.8148L17.5191 14.6684L18.6505 13.537L19.7969 14.6833L20.9432 13.537Z"
            fill="#E2FF31"/>
        <defs>
          <linearGradient id="paint0_linear" x1="17" y1="0" x2="17" y2="32" gradientUnits="userSpaceOnUse">
            <stop stop-color="#303030"/>
            <stop offset="1" stop-color="#121212"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="infoBlock">
      <p class="infoBlockParagraph">Ваш матч с
        <span class="infoBlockParagraph_opacityColor">«{{ opponent.name }}»</span>
        начался, присоединяйтесь
        <router-link target="_blank" :to="{name: 'SingleMatch', params: {id: match.id}}">
          <span class="infoBlockParagraph_yellowColor">в&nbsp;комнату&nbsp;матча.</span>
        </router-link>
      </p>
      <time class="infoBlockTime">{{ notificationTime }}</time>
    </div>
  </div>
</template>

<script>
import {notificationsMixin} from "@/otherJS/mixins/notificationsMixin";

export default {
  name: "NotificationAboutStartMatch",
  mixins: [notificationsMixin],
  props: ['item'],
}

</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

.container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;

  .avatar {
    max-width: 32px;
    height: 32px;
    border-radius: 13px;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoBlock {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    .infoBlockParagraph {
      color: #ffffff;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-size: 14px;
      line-height: 17px;
      font-weight: normal;

      &_opactityColor {
        opacity: .6;
      }

      &_yellowColor {
        color: var(--tournament-color);
        text-decoration: underline;
        white-space: nowrap;
      }
    }

    .infoBlockTime {
      margin-top: 4px;
      color: #757575;
      font-size: 14px;
      line-height: 17px;
      font-weight: normal;
      font-family: $blatant-cyrillic, $blatant-latin;
    }
  }
}

</style>
