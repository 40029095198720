export default {
    state: {
        sliderBanners: null
    },

    actions: {
        async SET_SLIDER_BANNERS({commit}) {
            const data = [{
                id: '0',
                url: require('@/img/hero-bg.jpg'),
                discipline: require('@/img/disciplines-logo/counter-strike.png'),
                sponsors: [{
                    id: '0',
                    logoUrl: require('@/img/partners-logo/logo3.png')
                }, {
                    id: '1',
                    logoUrl: require('@/img/partners-logo/logo4.png')
                }]
            }, {
                id: '1',
                url: require('@/img/hero-bg.jpg'),
                discipline: require('@/img/disciplines-logo/counter-strike.png'),
                sponsors: [{
                    id: '0',
                    logoUrl: require('@/img/partners-logo/logo3.png')
                }, {
                    id: '1',
                    logoUrl: require('@/img/partners-logo/logo4.png')
                }]
            }, {
                id: '2',
                url: require('@/img/hero-bg.jpg'),
                discipline: require('@/img/disciplines-logo/counter-strike.png'),
                sponsors: [{
                    id: '0',
                    logoUrl: require('@/img/partners-logo/logo3.png')
                }, {
                    id: '1',
                    logoUrl: require('@/img/partners-logo/logo4.png')
                }]
            }]

            commit('SET_SLIDER_BANNERS', data)
        }
    },

    mutations: {
        SET_SLIDER_BANNERS(state, payload) {
            state.sliderBanners = payload
        }
    },

    getters: {
        GET_SLIDER_BANNERS(state) {
            if (state.sliderBanners) return state.sliderBanners
            return []
        }
    }
}