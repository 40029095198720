import {API} from '../axios-config';
import _ from 'lodash';
import {getDataForRelation, includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import GetGroupWinners from "@/otherJS/getGroupWinners/getGroupWinners";
import {getPlayOffSEWinners} from "@/otherJS/getPlayOffWinners/getPlayOffSEWinners";
import currentPageAndLastPageIsEqual from "@/otherJS/currentPageAndLastPageIsEqual";
import generateTournaments from "@/otherJS/generateTournaments";
import chat from "@/Vuex/modules/chat";
import {SPONSOR_ID} from "@/config";
import router from "@/router";
import {isUserHaveActiveTournamentRequest} from "@/otherJS/utils";
import {sortGroupsByGrouping} from "@/otherJS/sort/sortGroupsByGrouping";
import {getPlayOffDEWinners} from "@/otherJS/getPlayOffWinners/getPlayOffDEWinners";
import Vue from "vue";
import TournamentRepository from "@/components/Repositories/TournamentRepository/TournamentRepository";


/*
* PO - playoff
* RR - round robin
*/

const matchesConfig = {
    pageNumber: 1,
    pageSize: 10,
};

export default {
    state: {
        isTournamentsInRequest: false,
        isParticipantsInRequest: false,
        isPoReq: true,
        isRrReq: true,
        tournaments: [],
        tournamentsMeta: null,
        tournamentsConfig: {
            startPage: 1,
            size: 10,
            allDataIsLoaded: false,
        },
        tournament: {
            entries: {},
            // заявка в конкретный турнир
            participant_entry: null
        },
        matches: [],
        rr_matches: [],
        matches_meta: null,
        participants: [],
        matchesGroups: {},
        playoff: {},
        match: {},
        match_compositions: [],
        isMatchInRequest: false,
        tournament_winners: {},
        tournamentsListFilter: {
            game_id: null
        }
    },
    mutations: {
        SET_TOURNAMENTS_TO_STATE: (state, data) => {
            state.tournaments = data;
        },

        SET_UPDATED_TOURNAMENT_BY_IDX: (state, data) => {
            // state.tournaments[data.idx] = data.tournament;
            Vue.set(state.tournaments, data.idx, data.tournament);
        },

        CLEAR_TOURNAMENTS: (state) => state.tournaments = [],
        SET_TOURNAMENTS_IN_REQUEST: (state, request) => {
            state.isTournamentsInRequest = request;
        },
        SET_PARTICIPANTS_IN_REQUEST: (state, request) => {
            state.isParticipantsInRequest = request;
        },
        SET_TOURNAMENTS_META(state, data) {
            state.tournamentsMeta = data;
        },
        SET_TOURNAMENTS_CONFIG(state, data) {
            state.tournamentsConfig = data;
        },
        SET_TOURNAMENT_TO_STATE: (state, tournament) => {
            state.tournament = tournament;
        },
        SET_PARTICIPANTS_TO_STATE: (state, participants) => {
            state.participants = participants;
        },
        SET_TOURNAMENT_ENTRIES: (state, entries) => {
            state.tournament.entries = entries;
        },
        SET_GROUPS: (state, groups) => {
            state.matchesGroups = groups;
        },
        SET_PO_DATA: (state, playoff) => {
            state.playoff = playoff;
        },
        IS_PO_REQ: (state, req) => {
            state.isPoReq = req;
        },
        IS_Rr_REQ: (state, req) => {
            state.isRrReq = req;
        },
        SET_MATCHES: (state, matches) => {
            state.matches = matches;
        },
        SET_RR_MATCHES: (state, rr_matches) => {
            state.rr_matches = rr_matches;
        },

        SET_MATCHES_META(state, meta) {
            state.matches_meta = meta;
        },

        SET_MATCH: (state, match) => {
            state.match = match;
        },
        SET_CURRENT_COMPOSITION: (state, composition) => {
            state.match_compositions.push(composition);
        },
        SET_MATCH_IN_REQUEST: (state, req) => {
            state.isMatchInRequest = req;
        },

        SET_WINNERS: (state, winners) => {
            state.tournament_winners = winners;
        },

        SET_PARTICIPANT_ENTRY: (state, entry) => {
            state.tournament.participant_entry = entry;
        },

        SET_TOURNAMENTS_LIST_FILTER: (state, filter) => {
            for (let key in filter) {
                state.tournamentsListFilter[key] = filter[key];
            }
        }
    },
    actions: {
        SET_PARTICIPANT_ENTRY({commit}, entry) {
            commit('SET_PARTICIPANT_ENTRY', entry);
        },

        GET_TOURNAMENTS({commit, getters, dispatch, state}) {

            const {startPage, size} = getters.GET_TOURNAMENTS_CONFIG;

            commit('SET_TOURNAMENTS_IN_REQUEST', true);
            let filter = '';
            state.tournamentsListFilter.game_id !== null ? filter += `&filter[game_id]=${state.tournamentsListFilter.game_id}` : '';
            const url = `/tournaments?include=background,game&page[number]=${startPage}&page[size]=${size}${filter}&filter[sponsor_id]=${SPONSOR_ID}&sort=sort,index_of_status,-started_at`;

            return API({
                url,
                method: "GET"
            }).then(({data}) => {

                if (!data.data.length) {
                    commit('SET_TOURNAMENTS_TO_STATE', []);
                    commit('SET_TOURNAMENTS_IN_REQUEST', false);

                    // Записываю meta турниров, чтобы знать на какой странице по поиску находимся
                    dispatch('SET_TOURNAMENTS_META', data.meta);

                    const pageStatus = currentPageAndLastPageIsEqual(data.meta);
                    dispatch('SET_TOURNAMENTS_CONFIG', pageStatus);
                    return;
                }

                let tournaments = generateTournaments(data);

                dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments}).then((res) => {
                    tournaments = res;
                });

                commit('SET_TOURNAMENTS_TO_STATE', tournaments);
                commit('SET_TOURNAMENTS_IN_REQUEST', false);


                // Записываю meta турниров, чтобы знать на какой странице по поиску находимся
                dispatch('SET_TOURNAMENTS_META', data.meta);

                const pageStatus = currentPageAndLastPageIsEqual(data.meta);
                dispatch('SET_TOURNAMENTS_CONFIG', pageStatus);
            });
        },

        async SET_MORE_TOURNAMENTS({commit, getters, dispatch, state}) {

            const oldTournaments = getters.TOURNAMENTS;
            const meta = getters.GET_TOURNAMENTS_META;
            const {size} = getters.GET_TOURNAMENTS_CONFIG;

            const nextPage = meta.page['current-page'] + 1;

            let filter = '';
            state.tournamentsListFilter.game_id !== null ? filter += `&filter[game_id]=${state.tournamentsListFilter.game_id}` : '';
            const url = `/tournaments?include=background,game&page[number]=${nextPage}&page[size]=${size}${filter}&filter[sponsor_id]=${SPONSOR_ID}&sort=sort,index_of_status,-started_at`;

            try {
                const {data} = await API({
                    url,
                    method: "GET"
                });

                dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments: data.data}).then((res) => {
                    data.data = res;
                });

                const newTournaments = generateTournaments(data, oldTournaments);
                commit('SET_TOURNAMENTS_TO_STATE', newTournaments);

                // Записываю meta турниров, чтобы знать на какой странице по поиску находимся
                dispatch('SET_TOURNAMENTS_META', data.meta);

                const pageStatus = currentPageAndLastPageIsEqual(data.meta);
                dispatch('SET_TOURNAMENTS_CONFIG', pageStatus);

            } catch (e) {
                console.log(`tournaments SET_MORE_TOURNAMENTS ${e}`);
                throw new Error(`tournaments SET_MORE_TOURNAMENTS ${e}`);
            }
        },

        SET_TOURNAMENTS_META({commit}, data) {
            commit('SET_TOURNAMENTS_META', data);
        },

        SET_TOURNAMENTS_CONFIG({commit, getters}, data) {

            const tournamentConfig = {...getters.GET_TOURNAMENTS_CONFIG};

            const tempData = {
                ...tournamentConfig,
                allDataIsLoaded: data,
            };

            commit('SET_TOURNAMENTS_CONFIG', tempData);
        },

        SET_TOURNAMENTS_REQUEST({commit}, req) {
            commit('SET_TOURNAMENTS_IN_REQUEST', req);
        },

        /**
         * Обновляем какой-то 1 турнир в списке турниров
         * */
        async UPDATE_TOURNAMENT_IN_LIST({commit, getters, dispatch}, updatedTournament) {
            const tournamentIdx = this.getters.TOURNAMENTS.findIndex(t => Number(t.id) === Number(updatedTournament.id));
            let tournament = await TournamentRepository.getById(updatedTournament.id, ['background', 'game']);

            dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments: [tournament]}).then((res) => {
                tournament = res[0];
            });

            commit('SET_UPDATED_TOURNAMENT_BY_IDX', {
                tournament: tournament,
                idx: tournamentIdx
            });
        },

        async GET_TOURNAMENT_BY_ID({commit, dispatch, getters}, data) {
            if (data.silently) {
                commit('SET_TOURNAMENTS_IN_REQUEST', false);
            } else {
                commit('SET_TOURNAMENTS_IN_REQUEST', true);
            }

            let tournament = await TournamentRepository.getById(data.id, ['background', 'game']);

            commit('SET_TOURNAMENT_TO_STATE', tournament);
            await dispatch('IS_ENTRY_CREATED', {
                id: tournament.id,
                type: tournament.attributes.participant_type
            });

            await dispatch('GET_TOURNAMENT_ENTRIES', data);
            commit('SET_TOURNAMENTS_IN_REQUEST', false);
        },

        GET_TOURNAMENT_ENTRIES({commit, dispatch, getters}, data) {
            commit('SET_PARTICIPANTS_IN_REQUEST', true);
            API({
                url: `/entries?filter[receivable_type]=tournaments&filter[receivable_id]=${data.id}&filter[status]=pending,confirmed&include=sendable`,
                method: 'GET',
            })
                .then(({data}) => {
                    if (data.data.length && !!data.included) {
                        let sendables = data.included
                            .filter(item => { return item.type === 'teams' || item.type === 'users'; })
                            .map(item => {
                                let tournamentEntry = data.data.filter(entry => {
                                    const sendable = entry.relationships.sendable;
                                    const sData = sendable !== null ? sendable.data : null;

                                    return sData !== null && sData.id === item.id && sData.type === item.type;
                                })[0];

                                item.entrie = {
                                    'id': tournamentEntry.id,
                                    'kind': tournamentEntry.attributes.kind,
                                    'status': tournamentEntry.attributes.status,
                                };

                                return item;
                            });

                        let pending = [];
                        let confirmed = [];

                        sendables.map(entry => entry.entrie.status === 'pending' ? pending.push(entry) : confirmed.push(entry));

                        let filteringEntries = {
                            pending,
                            confirmed
                        };

                        commit('SET_PARTICIPANTS_TO_STATE', confirmed);
                        commit('SET_TOURNAMENT_ENTRIES', filteringEntries);
                    } else {
                        commit('SET_TOURNAMENT_ENTRIES', {pending: [], confirmed: []});
                    }

                }).then(() => {
                    if (getters.TOURNAMENT.attributes.status === 'in_process' || getters.TOURNAMENT.attributes.status === 'finished') {

                        if (getters.TOURNAMENT.attributes.group_type !== null) dispatch('GET_MATCHES_RR', {id: data.id, stage: getters.TOURNAMENT.attributes.group_type});
                        if (getters.TOURNAMENT.attributes.playoff_type !== null) dispatch('GET_MATCHES_PO', {id: data.id, stage: getters.TOURNAMENT.attributes.playoff_type})

                    }
                }).then(() => {
                    commit('SET_PARTICIPANTS_IN_REQUEST', false);
                });
        },
        GET_MATCHES_RR({commit, getters}, data) {
            commit('IS_Rr_REQ', true);

            return API({
                url: `tournaments/${data.id}/matches?filter[stage]=${data.stage}&fields[matches]=first_score,second_score,grouping,participantable_first_id,participantable_second_id,participantable_first_type,stage,status,rounds&include=rounds`,
                method: "GET"
            }).then(({data}) => {
                let groups = {};
                let rounds = includeFilteringByType(data.included, 'rounds');

                /*
                * Приписюниваем раундсы к матчам
                * и раскидываем матчи по гроупингам
                * */
                data.data.map(match => {
                    match.rounds = rounds.find(round => Number(round.id) === Number(match.relationships.rounds.data.id));

                    if (groups[match.attributes.grouping] === undefined) {
                        groups[match.attributes.grouping] = [];
                    }
                    groups[match.attributes.grouping].push(match);
                });

                commit('SET_RR_MATCHES', data.data);
                let sortedGroups = sortGroupsByGrouping(groups);

                commit('SET_GROUPS', sortedGroups);
                commit('IS_Rr_REQ', false);


                /*
                * Собираем победителей. Если групп будет больше, чем 1, то победители будут все ровно из первой группы
                * */
                if (getters.TOURNAMENT.attributes.playoff_type === null) {
                    if (getters.TOURNAMENT.attributes.finished_at !== null) {
                        let firstGroupMatches = Object.entries(sortedGroups)[0][1];

                        commit('SET_WINNERS', (new GetGroupWinners(firstGroupMatches)).getWinners());
                    } else {
                        return {};
                    }
                }
            });
        },
        GET_MATCHES_PO({commit, getters, state}, data) {
            commit('IS_PO_REQ', true);
            const filteringInGroup = (arr) => {
                let participants = [];
                let matches = {};
                arr.map(item => {

                    if (matches[item.attributes.depth] === undefined) {
                        matches[item.attributes.depth] = [];
                    }

                    matches[item.attributes.depth].push(item);
                    participants.push(item.attributes.participantable_first_id);
                    participants.push(item.attributes.participantable_second_id);
                    participants = _.uniq(participants);
                });
                return {
                    participants,
                    matches
                };
            };
            return API({
                url: `/tournaments/${data.id}/matches?filter[stage]=${data.stage}&include=rounds`,
                method: "GET"
            }).then(({data}) => {

                // Вставляю в матч информацию о rounds

                try {
                    data.data.forEach((item) => {
                        item.rounds = getDataForRelation(data.included, item.relationships.rounds.data);
                    });
                } catch (e) {
                    console.log('GET_MATCHES_PO', e);
                }


                let sortingData = {};
                data.data.map(item => {
                    if (sortingData[item.attributes.grouping] === undefined) {
                        sortingData[item.attributes.grouping] = [];
                    }
                    sortingData[item.attributes.grouping].push(item);
                });

                for (let key in sortingData) {
                    sortingData[key] = filteringInGroup(sortingData[key]);
                }

                commit('SET_PO_DATA', sortingData);
            }).then(() => {
                commit('IS_PO_REQ', false);
            }).then(() => {

                    if (getters.TOURNAMENT.attributes.finished_at !== null) {
                        if (state.tournament.attributes.playoff_type === 'single_elimination') {
                            commit('SET_WINNERS', getPlayOffSEWinners(state.playoff.upper_grid.matches))
                        }
                        if (state.tournament.attributes.playoff_type === 'double_elimination') {
                            commit('SET_WINNERS', getPlayOffDEWinners(state.playoff.final_grid.matches[Object.keys(state.playoff.final_grid.matches)[0]][0], state.playoff.lower_grid.matches[Object.keys(state.playoff.lower_grid.matches)[0]][0]))
                        }
                    } else {
                        return {};
                    }
            })
        },

        IS_ENTRY_CREATED({commit, getters}, tournament) {
            const accessToken = localStorage.getItem('userAccessToken');

            return API({
                url: `/entries?filter[receivable_type]=tournaments&filter[receivable_id]=${tournament.id}&filter[status]=pending,confirmed&include=sendable&fields[sendable]=id`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            }).then(({data}) => {
                let entries = data.data;
                let participantEntry = null;

                if (tournament.type === 'USER') {
                    participantEntry = entries.find(entry => Number(entry.relationships.sendable.data.id) === Number(getters.USER.id));

                    commit('SET_PARTICIPANT_ENTRY', participantEntry !== undefined ? participantEntry : null);

                } else if (tournament.type === 'TEAM') {
                    for (const team of getters.USER_MANAGED_TEAMS) {
                        for (const entry of entries) {
                            if (Number(team.id) === Number(entry.relationships.sendable.data?.id)) {
                                participantEntry = entry;
                                break;
                            }
                        }
                    }

                    commit('SET_PARTICIPANT_ENTRY', participantEntry);
                }
                return data;
            });
        },

        async NEW_IS_ENTRY_CREATED({commit, getters}) {

            const tournament = {...getters.TOURNAMENT};
            const accessToken = localStorage.getItem('userAccessToken');

            try {
                const response = await API({
                    url: `/entries?filter[receivable_type]=tournaments&filter[receivable_id]=${tournament.id}&filter[status]=pending,confirmed&include=sendable&fields[sendable]=id`,
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    },
                });

                let entries = response.data.data;
                let participantEntry = null;

                if (tournament.attributes.participant_type === 'USER') {
                    participantEntry = entries.find(entry => Number(entry.relationships.sendable.data.id) === Number(getters.USER.id));

                    commit('SET_PARTICIPANT_ENTRY', participantEntry !== undefined ? participantEntry : null);

                } else if (tournament.attributes.participant_type === 'TEAM') {
                    // getters.USER_MANAGED_TEAMS.forEach(team => {
                    //     participantEntry = entries.find(entry => Number(team.id) === Number(entry.relationships.sendable.data.id))
                    // })

                    for (const team of getters.USER_MANAGED_TEAMS) {
                        for (const entry of entries) {
                            if (Number(team.id) === Number(entry.relationships.sendable.data.id)) {
                                participantEntry = entry;
                                break;
                            }
                        }
                    }

                    commit('SET_PARTICIPANT_ENTRY', participantEntry);
                }

                return response.data;


            } catch (e) {
                console.log('IS_ENTRY_CREATED', e);
                throw new Error(`IS_ENTRY_CREATED, ${e}`);
            }
        },

        CREATE_TOURNAMENT_ENTRIE({commit, dispatch, getters}, data) {
            return API({
                url: '/entries',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": {
                        "type": "entries",
                        "attributes": {
                            "status": "pending",
                            "kind": "toTournament"
                        },
                        "relationships": {
                            "sendable": {
                                "data": {
                                    "type": data.participantable_type,
                                    "id": data.participantable_id.toString()
                                }
                            },
                            "receivable": {
                                "data": {
                                    "type": "tournaments",
                                    "id": data.tournament_id.toString()
                                }
                            }
                        }
                    }
                }
            }).then(() => {
                // dispatch('CREATE_COMPOSITION_FOR_TOURNAMENT', data);
                // dispatch('IS_ENTRY_CREATED', data.tournament)
                let isNeededRout = !!router.currentRoute.name.match(/tournaments./);

                if (isNeededRout) {
                    console.log('update after reg');
                    dispatch('GET_TOURNAMENT_BY_ID', {id: router.currentRoute.params.id});
                }

                const event = new Event('tournament-entry-created');
                window.dispatchEvent(event);
            });
        },
        //TODO композишин фор турнамент теперь на бэке делается
        CREATE_COMPOSITION_FOR_TOURNAMENT({commit}, data) {
            return API({
                url: '/compositions-for-tournaments',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": {
                        "type": "compositions-for-tournaments",
                        "attributes": {
                            "participantable_id": Number(data.participantable_id),
                            "participantable_type": data.participantable_type,
                            "tournament_id": Number(data.tournament_id),
                        },
                        "relationships": {
                            "users": {
                                "data": data.usersData,
                                "meta": {
                                    "roles": data.usersMeta
                                },
                            }
                        }
                    }
                }
            });
        },
        GET_UPCOMING_MATCHES({commit}, data) {
            const {pageNumber, pageSize} = matchesConfig;

            return API({
                url: `/tournaments/${data.id}/matches?sort=-status,started_at&page[number]=${pageNumber}&page[size]=${pageSize}&include=rounds`,
                method: 'GET',
            }).then(({data}) => {

                data.data.forEach((item) => {
                    item.rounds = getDataForRelation(data.included, item.relationships.rounds.data);
                });

                commit('SET_MATCHES', data.data);
                commit('SET_MATCHES_META', data.meta);
            });
        },
        GET_MATCHES_BY_USER({commit}, data) {

            return API({
                url: `/tournaments/${data.id}/matches?sort=-status,started_at&include=rounds&filter[user_id]=${data.userId}`,
                method: 'GET',
            })
                .then(({data}) => {
                    data.data.forEach((item) => {
                        item.rounds = getDataForRelation(data.included, item.relationships.rounds.data);
                    });

                    const uniqueMatches = _.uniqBy(data.data, 'id');

                    commit('SET_MATCHES', uniqueMatches);
                });

        },

        async SET_MORE_MATCHES({commit, getters}) {

            const matches = getters.MATCHES;
            const matchesMeta = getters.GET_MATCHES_META;
            const tournamentId = getters.TOURNAMENT.id;

            const {pageSize} = matchesConfig;
            const nextPage = matchesMeta.page['current-page'] + 1;

            const generateMatches = (newMatches) => [...matches, ...newMatches];

            try {
                const response = await API({
                    url: `/tournaments/${tournamentId}/matches?sort=-status,started_at&page[number]=${nextPage}&page[size]=${pageSize}&include=rounds`,
                    method: 'GET',
                });

                const responseData = response.data;

                responseData.data.forEach((item) => {
                    item.rounds = getDataForRelation(responseData.included, item.relationships.rounds.data);
                });

                const newMatches = generateMatches(responseData.data);
                const newMeta = responseData.meta;

                commit('SET_MATCHES', newMatches);
                commit('SET_MATCHES_META', newMeta);

            } catch (e) {
                console.log(`SET_MORE_MATCHES ${e}`);
                throw new Error(`SET_MORE_MATCHES ${e}`);
            }
        },

        async GET_MATCH_FROM_URL({commit, dispatch}, dataToReq) {
            if (!dataToReq.silently) {
                commit('SET_MATCH_IN_REQUEST', true);
            }

            const res = await API({
                method: 'GET',
                url: `/matches/${dataToReq.id}?include=tournament,rounds,rounds.scores,rounds.scores.attachments&fields[tournaments]=name,victory_on_points,sort_after_group,group_type,playoff_type&fields[matches]=chat_id,created_at,current_timestamp,depth,ended_at,first_score,grouping,nextMatch,participantable_first_id,participantable_first_type,participantable_second_id,participantable_second_type,second_score,stage,started_at,status,tournament_id,translation_url,updated_at,victory_on_points,previous_finished,tournament,rounds,rounds.scores`
            });
            const data = res.data;

            const attachments = includeFilteringByType(data.included, 'attachments');

            const matchScores = includeFilteringByType(data.included, 'scores').length ? includeFilteringByType(data.included, 'scores') : null;
            if (matchScores !== null) {
                matchScores.map(score => score.attachments = []);
                matchScores.map(score => {
                    score.relationships.attachments.data.map(attachment => {
                        score.attachments = score.attachments.concat(attachments.filter(includedAttach => includedAttach.id === attachment.id));
                    })
                });
            }

            let matchData = {
                data: data.data,
                rounds: includeFilteringByType(data.included, 'rounds').length ? includeFilteringByType(data.included, 'rounds')[0] : null,
                tournament_info: includeFilteringByType(data.included, 'tournaments')[0],
                scores: matchScores
            };

            commit('SET_MATCH', matchData);

            let firstParticipant = {
                participant_id: data.data.attributes.participantable_first_id,
                tournament_id: data.included[0].id
            };
            let secondParticipant = {
                participant_id: data.data.attributes.participantable_second_id,
                tournament_id: data.included[0].id
            };

            await dispatch('GET_CURRENT_MATCH_COMPOSITION', firstParticipant);
            await dispatch('GET_CURRENT_MATCH_COMPOSITION', secondParticipant);
            commit('SET_MATCH_IN_REQUEST', false);
        },

        GET_CURRENT_MATCH_COMPOSITION({commit}, data) {
            return API({
                method: 'GET',
                url: `/compositions-for-tournaments?include=participantable,users&filter[participantable_id]=${data.participant_id}&filter[tournament_id]=${data.tournament_id}`
            }).then(({data}) => {
                let composition = data.data[0];

                composition.participant = includeFilteringByType(data.included, composition.attributes.participantable_type)[0];

                if (composition.attributes.participantable_type === 'teams') {
                    composition.users = includeFilteringByType(data.included, 'users')
                        .map(item => {
                            item.role = composition.relationships.users.meta.roles.find(role => Number(role.id) === Number(item.id) ? role : null).role;
                            return item;
                        });

                    composition.players = composition.users.filter(item => item.role !== 'substitute');
                    composition.players.unshift(...composition.players.splice(_.findIndex(composition.players, item => item.role === 'capitan'), 1));
                    composition.subs = composition.users.filter(item => item.role === 'substitute');
                }

                commit('SET_CURRENT_COMPOSITION', composition);
            });
        },

        UPDATE_SCORES(ctx, data) {
            let url, dataToUpdate;
            if (data.isScores) {
                url = `/scores/${data.id}`;
                dataToUpdate = {
                    'type': 'scores',
                    'id': data.id,
                    'attributes': {
                        'scores': data.scores,
                        'enemy_scores': data.enemy_scores,
                        'etc': {'typeWin': data.typeWin}
                    }
                };
            } else {
                url = `/rounds/${data.id}`;
                dataToUpdate = {
                    'type': 'rounds',
                    'id': data.id,
                    'attributes': {
                        'first_score': data.first_score,
                        'second_score': data.second_score,
                        'etc': {'typeWin': data.typeWin}
                    }
                };
            }

            return API({
                method: 'PATCH',
                url,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    'data': dataToUpdate
                }
            }).then((res) => {
                // ctx.dispatch('GET_MATCH_FROM_URL', {id: ctx.getters.MATCH.data.id})
            });
        },

        DECLINE_ENTRY({commit}, data) {
            return API({
                url: `/entries/${data.entry.id}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": {
                        "type": "entries",
                        "id": data.entry.id,
                        "attributes": {
                            "status": "canceled",
                        }
                    }
                }
            }).then(res => {
                console.log('Заявка отозвана', res);
                commit('SET_PARTICIPANT_ENTRY', null);
            });
        },

        /**
         * Собираем массив из заявок статусами 'pending'&'confirmed', по турнирам, которые к нам пришли
         * для того, что бы сравнитиь их потом с юзером.
         *
         * @param payload - массив строк из идентификаторов турниров, по которым нужно собрать заявки.
         *
         * @return entries - массив объектов из заявок по турнирам
         * */
        async GET_ALL_TOURNAMENTS_ENTRIES(ctx, payload) {
            let ids = '';
            for (let i = 0; i < payload.length; i++) {
                ids += `${payload[i]},`;
            }
            ids = ids.slice(0, -1);

            const res = await API({
                url: `/entries?filter[receivable_type]=tournaments&filter[receivable_id]=${ids}&filter[status]=pending,confirmed&include=sendable,receivable&fields[sendable]=id`,
                method: "GET"
            });

            const data = res.data;

            if (data.data.length) {
                const users = includeFilteringByType(data.included, 'users');

                return data.data.filter(entry => {
                    entry.user = users.find(user => Number(user.id) === Number(entry.relationships.sendable.data.id));
                    return entry;
                });

            } else {
                return [];
            }

        },

        /**
         * Устанавливаем, имеется ли в каждом отдельном турнире заявка от тукущего пользователя.
         * Если да, то в превью турнира(карточке), вместо кнопки "Принять участие" показываем "Вы участвуете"
         *
         * @param payload - объект, содержащий массив турниров.
         *
         * @return tournaments - мутированный объект турниров, имеющих или нет, флага об активной заявке
         * */
        async SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT({dispatch, getters}, payload) {
            let tournamentsIds = payload.tournaments.map(el => el.id);
            const allEntries = await dispatch('GET_ALL_TOURNAMENTS_ENTRIES', tournamentsIds);
            payload.tournaments = isUserHaveActiveTournamentRequest(payload.tournaments, allEntries, getters.USER);

            return payload.tournaments;
        }
    },
    getters: {
        GET_TOURNAMENTS_CONFIG(state) {
            return state.tournamentsConfig;
        },

        GET_TOURNAMENTS_CONFIG_ALL_DATA_IS_LOADED(state) {
            try {
                return state.tournamentsConfig.allDataIsLoaded;
            } catch (e) {
                console.log('e', e);
                return false;
            }
        },

        GET_TOURNAMENTS_META(state) {
            return state.tournamentsMeta;
        },

        IS_SHOW_SPINNER(state) {
            try {
                const meta = state.tournamentsMeta;

                const currentPage = Number(meta.page['current-page']);
                const lastPage = Number(meta.page['last-page']);

                return currentPage < lastPage;


            } catch (e) {
                return false;
            }

        },

        IS_TOURNAMENTS_IN_REQUEST(state) {
            return state.isTournamentsInRequest;
        },
        IS_PARTICIPANTS_IN_REQUEST(state) {
            return state.isParticipantsInRequest;
        },
        TOURNAMENTS(state) {
            return state.tournaments;
        },
        TOURNAMENT(state) {
            return state.tournament;
        },
        PARTICIPANTS(state) {
            return state.participants;
        },
        GET_GROUPS(state) {
            return state.matchesGroups;
        },
        GET_PLAYOFF(state) {
            return state.playoff;
        },
        GET_PO_REQ(state) {
            return state.isPoReq;
        },
        GET_RR_REQ(state) {
            return state.isRrReq;
        },
        GET_RR_MATCHES(state) {
            return state.rr_matches;
        },
        MATCHES(state) {
            return state.matches;
        },
        GET_MATCHES_META(state) {
            return state.matches_meta;
        },
        MATCH(state) {
            return state.match;
        },
        IS_MATCH(state) {
            return state.isMatchInRequest;
        },
        COMPOSITIONS(state) {
            return state.match_compositions;
        },
        WINNERS(state) {
            return state.tournament_winners;
        },
        GET_TOURNAMENT_PARTICIPANT_ENTRY(state) {
            return state.tournament.participant_entry;
        }
    },
    modules: {
        chat
    }
};
