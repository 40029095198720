export default {
    state: {
        countries: {},
        languages: {},
        timeZones: {}
    },
    mutations: {
        SET_LANGUAGES: (state, data) => {
            state.languages = data
        },
        SET_COUNTRIES: (state, data) => {
            state.countries = data
        },
        SET_TIMEZONES: (state, data) => {
            state.timeZones = data
        }
    },
    actions: {

    },
    getters: {
        COUNTRIES(state) {
            return state.countries;
        },
        LANGUAGES(state) {
            return state.languages;
        },
        TIMEZONES(state) {
            return state.timeZones;
        }
    },
}
