<template>
  <div class="searchTeamsBlock" v-if="getIsHaveData">
    <header-block :headerBlock="headerBlock" />
    <search-team-block v-for="team of this.GET_RESPONSE_TEAMS_DROPDOWN.TEAMS" :key="team.id" :team="team" />
  </div>
</template>

<script>
import HeaderBlock from "@/components/searchDropDown/components/headerBlock/headerBlock";
import SearchTeamBlock from "@/components/searchDropDown/searchTeamsBlock/searchTeamBlock/searchTeamBlock";
import {mapGetters} from "vuex";
export default {
  name: "searchTeamsBlock",
  components: {SearchTeamBlock, HeaderBlock},
  computed: {
    ...mapGetters([
        'GET_RESPONSE_TEAMS_DROPDOWN'
    ]),
    headerBlock() {
      return {
        type: 'teams',
        count: this.GET_RESPONSE_TEAMS_DROPDOWN.COUNT
      }
    },

    getDataIsArray() {
      return Array.isArray(this.GET_RESPONSE_TEAMS_DROPDOWN.TEAMS)
    },

    getIsHaveData() {
      return this.getDataIsArray && this.GET_RESPONSE_TEAMS_DROPDOWN.TEAMS.length !== 0
    }
  },
}
</script>

<style lang="scss" scoped>
  .searchTeamsBlock {
    margin-top: 16.5px;
    padding-top: 24.5px;
    padding-bottom: 16px;

    border-top: rgba(255, 255, 255, .4) 1px solid;
    // border-bottom: rgba(255, 255, 255, .4) 1px solid;
  }




</style>