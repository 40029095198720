import {API} from "@/Vuex/axios-config";

export default {
    state: {},

    actions: {
        async FETCH_FAQ() {
            const response = await API({
                method: 'get',
                url: `/pages?filter[slug]=faq`
            })
            return response.data.data[0].attributes.content
        }
    },

    mutations: {},

    getters: {}
}
