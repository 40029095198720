
// Функция, которая в зависимости от фильтров и сортировки генерирует запрос.
const filterForEventGroups = (selectedFilter, selectedSort, typeOfSort, groupOfTournamentId, nextPage = 1) => {

    if (selectedFilter.value) return {
        method: 'get',
        url: `/tournaments?filter[group_of_tournaments_id]=${groupOfTournamentId}&page[number]=${nextPage}&page[size]=5&include=background,game&filter[index_of_status]=${selectedFilter.value}&sort=sort,${typeOfSort}${selectedSort}`
    }

    return {
        method: 'GET',
        url: `/tournaments?filter[group_of_tournaments_id]=${groupOfTournamentId}&page[number]=${nextPage}&page[size]=5&include=background,game&sort=sort,${typeOfSort}${selectedSort}`
    }
}

export default filterForEventGroups;