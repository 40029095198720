import {API} from "@/Vuex/axios-config";

const getSponsorInfoForEventGroups = async (SPONSOR_ID) => {
    try {
        const response = await API({
            method: 'get',
            url: `/sponsors/${SPONSOR_ID}?include=groupMiddleBannerDesktop,groupMiddleBannerTablet,groupMiddleBannerMobile`
        })

        return response.data
    } catch (e) {
        console.log('getSponsorInfoForEventGroups', e);
        throw new Error(`getSponsorInfoForEventGroups ${e}`)
    }
}

export default getSponsorInfoForEventGroups;