<template>
  <div class="notificationHaveNotContent">
    <span class="notificationHaveNotContent__text">У вас ещё нет уведомлений</span>
  </div>
</template>

<script>
export default {
name: "NotificationHaveNotContent"
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

  .notificationHaveNotContent {
    padding: 20px 0;

    &__text {
      color: white;
      font-size: 16px;
      line-height: 19.2px;
      font-weight: normal;
      font-family: $blatant-cyrillic, $blatant-latin;
    }
  }
</style>
