<template>
  <div class="warningModalContainer" @click="closeModal">
    <div class="warningModalBody" @click.stop>
      <div @click="closeModal" class="warningModalCloseButton">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
        </svg>
      </div>
      <div class="warningModalTitleWrapper">
        <span class="warningModalTitleWrapper__text">
          Вы уже состоите
        </span>
        <span class="warningModalTitleWrapper__text">
          в команде
        </span>
      </div>
      <div class="warningModalContent">
        <span>Чтобы принять приглашение - перейдите в </span>
        <!-- div -> router-link :to="{name: 'team.name', params: {id: getTeamId}}" target="_blank" @click.native="closeModal"-->
        <div class="link" @click="closeModal">{{ getTeamName }}</div>
        <span> и выйдите из состава по данной дисциплине, а затем повторите попытку.</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "warning-modal",
  data() {
    return {
      timer: null,
    }
  },
  methods: {
    ...mapActions([
      'SET_WARNING_MODAL_INACTIVE',
    ]),
    closeModal() {
      this.SET_WARNING_MODAL_INACTIVE()
    },
  },
  computed: {
    ...mapGetters([
      'GET_WARNING_MODAL_STATUS',
      'USER_TEAMS'
    ]),

    getTeamName() {
      return this.USER_TEAMS[0].attributes.name
    },

    getTeamId() {
      return this.USER_TEAMS[0].id
    }
  },

  mounted() {
    this.timer = setTimeout(this.closeModal, 2000);
  },

  destroyed() {
    clearTimeout(this.timer);
  }
}
</script>

<style lang="scss" scoped>

@import "src/scss/vars/colors";
@import "src/scss/vars/mixins";

.warningModalContainer {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0, .6);

  transition: opacity .4s ease;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .warningModalBody {
    position: relative;
    border-radius: 8px;
    background: $elemBg;
    max-width: 375px;
    width: 100%;
    padding: 40px 20px 57px;

    .warningModalCloseButton {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .warningModalTitleWrapper {
      display: flex;
      flex-direction: column;
      text-align: center;

      &__text {
        text-transform: uppercase;

        font-family: $blatant-cyrillic, $blatant-latin;
        letter-spacing: .2px;
        line-height: 28.8px;
        font-size: 24px;
        color: $red;
      }
    }

    .warningModalContent {
      margin-top: 27px;
      text-align: center;
      letter-spacing: .002em;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-size: 14px;
      line-height: 17px;
      color: $grey;

      .link {
        text-decoration: underline;
        color: var(--tournament-color);
      }
    }
  }
}
</style>