import {includeFindingByTag} from "@/otherJS/include-filtering";

class BannersImages {
    constructor(included, prefix) {
        this.included = included;
        this.prefix = prefix;
    }

    get desktop() {
        return {
            url: includeFindingByTag(this.included, `${this.prefix}_desktop`).attributes.url ?? '',
            optimizedUrl: includeFindingByTag(this.included, `${this.prefix}_desktop`).attributes.optimize_url ?? ''
        }
    }

    get tablet() {
        return {
            url: includeFindingByTag(this.included, `${this.prefix}_tablet`).attributes.url ?? '',
            optimizedUrl: includeFindingByTag(this.included, `${this.prefix}_tablet`).attributes.optimize_url ?? ''
        }
    }

    get mobile() {
        return {
            url: includeFindingByTag(this.included, `${this.prefix}_mobile`).attributes.url ?? '',
            optimizedUrl: includeFindingByTag(this.included, `${this.prefix}_mobile`).attributes.optimize_url ?? ''
        }
    }

    get getBanners() {
        return {
            desktop: this.desktop,
            tablet: this.tablet,
            mobile: this.mobile
        }
    }
}

export default BannersImages;
