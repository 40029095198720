import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import moment from "moment";

const generateTournaments = (response, oldTournaments = []) => {

    let newTournaments = response.data;
    let backgrounds = includeFilteringByTag(response.included, 'background');
    let games = includeFilteringByType(response.included, 'games');

    newTournaments.forEach(tournament => {
        tournament.background = backgrounds.filter(item => item.id === tournament.relationships.background.data.id)[0].attributes.url;
        tournament.game = games.filter(item => item.id === tournament.relationships.game.data.id)[0];
        tournament.participant_entry = null;
    })

    return [...oldTournaments, ...newTournaments];
}

export default generateTournaments;