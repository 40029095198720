export default {
    state: {
        isModalOpened: false,

        config: {
            text: 'Ваша заявка успешно подана!',
            status: 'default'
        },

    },

    actions: {
        SET_ACTIVE_UNIVERSAL_MODAL({commit}) {
            commit('SET_ACTIVE_UNIVERSAL_MODAL')
        },
        SET_INACTIVE_UNIVERSAL_MODAL({commit}) {
            commit('SET_INACTIVE_UNIVERSAL_MODAL')
        },
        SET_CONFIG_UNIVERSAL_MODAL({commit}, config) {
            commit('SET_CONFIG_UNIVERSAL_MODAL', config)
        },
    },

    mutations: {
        SET_ACTIVE_UNIVERSAL_MODAL(state) {
            state.isModalOpened = true
        },
        SET_INACTIVE_UNIVERSAL_MODAL(state) {
            state.isModalOpened = false
        },
        SET_CONFIG_UNIVERSAL_MODAL(state, config) {
            state.config = config
        }
    },

    getters: {
        GET_UNIVERSAL_MODAL_STATUS(state) {
            return state.isModalOpened;
        },
        GET_CONFIG_UNIVERSAL_MODAL(state) {
            return state.config
        }
    }
}