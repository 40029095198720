<template>
  <div class="blockHeader">
    <div class="headerLeftButton" @click="goToSearchScreen">
      <div class="headerLeftButton__icon" v-html="getIconType"></div>

      <span class="headerLeftButton__text">{{ getType }} ({{ getCount }})</span>
    </div>
    <div class="headerRightButton" @click="goToSearchScreen">
      <span class="headerRightButton__text">Смотреть все</span>
      <div class="headerRightButton__icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.75 3.75L12 9L6.75 14.25" stroke-width="1.5" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "headerBlock",
  props: ['headerBlock'],
  computed: {
    getType() {
      switch (this.headerBlock.type) {
        case 'users':
          return 'Игроки'
        case 'teams':
          return 'Команды'
        case 'tournaments':
          return 'Турниры'
        default:
          return ''
      }
    },
    getCount() {
      return this.headerBlock.count.toString();
    },
    getIconType() {
      switch (this.headerBlock.type) {
        case 'users':
          return `<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.8">
            <path d="M10.3889 4C9.28432 4 8.38889 4.89959 8.38889 6.00932C8.38889 7.11903 9.28432 8.01861 10.3889 8.01861C11.4935 8.01861 12.3889 7.11903 12.3889 6.00932C12.3889 4.89959 11.4935 4 10.3889 4ZM9.05555 8.41161C6.61111 9.08138 5.5 9.30463 5.5 14.2163L6.83333 14.886C7.27778 12.8767 7.27778 12.4302 7.5 12.4302C7.72222 12.4302 7.72222 13.1 8.16667 15.3326C9.5 15.5558 11.2778 15.5558 12.6111 15.3326C13.0556 13.1 13.0556 12.4302 13.2778 12.4302C13.5 12.4302 13.5 12.8767 13.9444 14.886L15.2778 14.4395C15.2778 9.30462 14.1667 9.08138 11.7222 8.41161C10.8333 9.08138 9.94444 9.08138 9.05555 8.41161Z" fill="white"/>
          </g>
        </svg>`

        case 'teams':
          return `<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M5.5 4C4.64089 4 3.94444 4.79965 3.94444 5.78605C3.94444 6.77247 4.64089 7.57209 5.5 7.57209C6.35911 7.57209 7.05555 6.77247 7.05555 5.78605C7.05555 4.79965 6.35911 4 5.5 4ZM9.5 4C8.39543 4 7.5 4.89958 7.5 6.00932C7.5 7.11903 8.39543 8.01861 9.5 8.01861C10.6046 8.01861 11.5 7.11903 11.5 6.00932C11.5 4.89958 10.6046 4 9.5 4ZM13.5 4C12.6409 4 11.9444 4.79965 11.9444 5.78605C11.9444 6.77247 12.6409 7.57209 13.5 7.57209C14.3591 7.57209 15.0556 6.77247 15.0556 5.78605C15.0556 4.79965 14.3591 4 13.5 4ZM4.40972 8.00465C2.40972 8.54874 1.5 8.73105 1.5 12.7209L2.59028 13.2651C2.95392 11.6329 2.95707 11.2698 3.13889 11.2698C3.32071 11.2698 3.31692 11.8143 3.68056 13.6279C3.84207 13.6548 4.01231 13.6788 4.1875 13.6977C4.25551 11.8358 4.52762 10.7069 5.11806 9.94419C5.80542 9.05632 6.81967 8.81656 8.02778 8.48607C7.63231 8.29038 7.15611 8.15859 6.59028 8.00465C5.86301 8.54874 5.137 8.54874 4.40972 8.00465ZM12.4097 8.00465C11.8379 8.16023 11.3561 8.28523 10.9583 8.47907C12.1732 8.81181 13.1942 9.0579 13.8819 9.97907C14.4512 10.7417 14.7261 11.867 14.8056 13.6977C14.9832 13.6788 15.1558 13.6551 15.3194 13.6279C15.6831 11.8143 15.6793 11.2698 15.8611 11.2698C16.0429 11.2698 16.0461 11.6329 16.4097 13.2651L17.5 12.9023C17.5 8.7311 16.5903 8.54874 14.5903 8.00465C13.863 8.54874 13.137 8.54874 12.4097 8.00465ZM8.16667 8.91161C5.72222 9.58139 4.61111 9.80463 4.61111 14.7163L5.94444 15.386C6.38889 13.3767 6.38889 12.9302 6.61111 12.9302C6.83333 12.9302 6.83333 13.6 7.27778 15.8326C8.61111 16.0558 10.3889 16.0558 11.7222 15.8326C12.1667 13.6 12.1667 12.9302 12.3889 12.9302C12.6111 12.9302 12.6111 13.3767 13.0556 15.386L14.3889 14.9395C14.3889 9.80462 13.2778 9.58139 10.8333 8.91161C9.94444 9.58139 9.05555 9.58139 8.16667 8.91161Z" fill="white"/>
</g>
</svg>
`
        case 'tournaments':
          return `<svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.8">
<path d="M15.2939 15.3793C15.2798 15.4082 15.2798 15.4371 15.2939 15.4661L15.8831 16.2036C15.9392 16.2614 15.8831 16.3627 15.813 16.3627H13.6945V14.4538H15.827C15.9112 14.4538 15.9532 14.555 15.8971 14.6128L15.2939 15.3793ZM4.25291 14.6128L4.84213 15.3504C4.85616 15.3793 4.85616 15.4082 4.84213 15.4371L4.23888 16.2036C4.19679 16.2614 4.25291 16.3627 4.32305 16.3627H6.44146V14.4538H4.33708C4.25291 14.4538 4.21082 14.5405 4.25291 14.6128ZM13.7928 4.56222H16.332V6.57234C16.332 8.01847 15.1957 9.18984 13.7928 9.18984H13.4701C12.9931 10.2744 12.0391 11.0987 10.8747 11.359V12.9932H11.7866C12.0672 12.9932 12.2916 13.2245 12.2916 13.5138V14.2079H13.0632V16.6663H7.10083V14.2079H7.87244V13.5138C7.87244 13.2245 8.0969 12.9932 8.37749 12.9932H9.28938V11.3446C8.12496 11.0843 7.17098 10.2744 6.69398 9.17538H6.37131C4.96839 9.17538 3.83203 8.00401 3.83203 6.55788V4.56222H6.37131V3.33301H13.7928V4.56222ZM6.37131 7.8594C6.37131 7.77263 6.35728 7.70033 6.35728 7.61356V5.89266H5.10869V6.57234C5.10869 7.28095 5.66985 7.8594 6.37131 7.8594ZM13.7928 5.89266V7.61356C13.7928 7.70033 13.7927 7.78709 13.7787 7.8594H13.7928C14.4802 7.8594 15.0413 7.28095 15.0413 6.57234V5.89266H13.7928Z" fill="white"/>
</g>
</svg>
`
        default:
          return ''
      }
    },
  },

  methods: {
    ...mapActions([
        'SET_SEARCH_TAB',
        'SET_FULL_SEARCH_RESPONSE',
        'DELETE_FULL_SEARCH_RESPONSE',
        'SET_PREVENT_INPUT_FOCUS',
        'SET_SHOW_DROPDOWN',
        'SET_STATUS_MOBILE_NAV',
        'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN'
    ]),

    goToSearchScreen() {
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();

      this.SET_SEARCH_TAB(this.headerBlock.type);
      this.SET_FULL_SEARCH_RESPONSE();
      this.SET_STATUS_MOBILE_NAV(false);
    }
  }
}
</script>

<style lang="scss" scoped>

.blockHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .headerLeftButton {
    cursor: pointer;
    padding: 2px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background-color: #3A3A3A;

    &__icon {
      width: 20px;
      height: 20px;

      svg {
        width: 100%;
        height: 100%;

        path {
          stroke: #B0B0B0
        }
      }
    }

    &__text {
      margin-left: 2px;
      font-family: 'SF UI Text', serif;
      font-weight: normal;
      font-size: 14px;
      line-height: 16.8px;
      color: white;
      opacity: .8;
    }

    &:hover {
      background-color: lighten(#3A3A3A, 10);
    }
  }

  .headerRightButton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__text {
      font-family: 'SF UI Text', serif;
      font-size: 14px;
      line-height: 16.8px;
      font-weight: normal;
      color: #B0B0B0;
    }

    &__icon {
      display: flex;
      margin-left: 4px;

      svg {
        path {
          stroke: #B0B0B0
        }
      }
    }

    &:hover {
      .headerRightButton__text {
        color: lighten(#B0B0B0, 10);
      }

      .headerRightButton__icon {
        svg {
          path {
            stroke: lighten(#B0B0B0, 10);
          }
        }
      }
    }
  }
}

</style>