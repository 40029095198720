<template>
  <div class="profile-header" v-if="!AUTH.isRequest">
    <div class="container">
      <div class="profile-header__wrapper">
        <div class="profile-title">
          <media :query="{minWidth: 769}">
            <div class="ph-share"><!--Поделиться--></div>
          </media>
          <div class="ph-title">Настройки аккаунта</div>
        </div>

        <label class="profile-image">
          <media :query="{maxWidth: 768}">
            <div class="ph-share"><!--Поделиться--></div>
          </media>
          <div class="ph-ava">
            <img :src="`${USER.logo}`" alt="">
            <div class="hover_text">
              Загрузить<br>фото
            </div>
          </div>

          <input type="file" ref="avatar" @input="avatar">
          <media :query="{minWidth: 769}">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M29.8863 27.219C30.3864 26.7189 30.6673 26.0406 30.6673 25.3333V10.6667C30.6673 9.95942 30.3864 9.28115 29.8863 8.78105C29.3862 8.28095 28.7079 8 28.0006 8H22.6673L20.0007 4H12.0007L9.33398 8H4.00065C3.29341 8 2.61513 8.28095 2.11503 8.78105C1.61494 9.28115 1.33398 9.95942 1.33398 10.6667V25.3333C1.33398 26.0406 1.61494 26.7189 2.11503 27.219C2.61513 27.719 3.29341 28 4.00065 28H28.0006C28.7079 28 29.3862 27.719 29.8863 27.219ZM11.6674 17.3333C11.6674 14.9401 13.6075 13 16.0007 13C18.394 13 20.3341 14.9401 20.3341 17.3333C20.3341 19.7266 18.394 21.6667 16.0007 21.6667C13.6075 21.6667 11.6674 19.7266 11.6674 17.3333ZM16.0007 11C12.5029 11 9.66739 13.8355 9.66739 17.3333C9.66739 20.8311 12.5029 23.6667 16.0007 23.6667C19.4985 23.6667 22.3341 20.8311 22.3341 17.3333C22.3341 13.8355 19.4985 11 16.0007 11Z"
                      fill="white"/>
              </g>
            </svg>
          </media>
        </label>

        <media :query="{minWidth: 769}">
          <div class="ph-since">Вместе с {{ USER.attributes.createdAt|since }}</div>
        </media>

        <!-- обложка профиля -->
        <media :query="{minWidth: 769}">
          <label class="profile-header__image">
            <input type="file" @input="background" ref="background">
            <svg
                @mouseover="isBgScale = true"
                @mouseleave="isBgScale = false"
                width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M29.8863 27.219C30.3864 26.7189 30.6673 26.0406 30.6673 25.3333V10.6667C30.6673 9.95942 30.3864 9.28115 29.8863 8.78105C29.3862 8.28095 28.7079 8 28.0006 8H22.6673L20.0007 4H12.0007L9.33398 8H4.00065C3.29341 8 2.61513 8.28095 2.11503 8.78105C1.61494 9.28115 1.33398 9.95942 1.33398 10.6667V25.3333C1.33398 26.0406 1.61494 26.7189 2.11503 27.219C2.61513 27.719 3.29341 28 4.00065 28H28.0006C28.7079 28 29.3862 27.719 29.8863 27.219ZM11.6674 17.3333C11.6674 14.9401 13.6075 13 16.0007 13C18.394 13 20.3341 14.9401 20.3341 17.3333C20.3341 19.7266 18.394 21.6667 16.0007 21.6667C13.6075 21.6667 11.6674 19.7266 11.6674 17.3333ZM16.0007 11C12.5029 11 9.66739 13.8355 9.66739 17.3333C9.66739 20.8311 12.5029 23.6667 16.0007 23.6667C19.4985 23.6667 22.3341 20.8311 22.3341 17.3333C22.3341 13.8355 19.4985 11 16.0007 11Z"
                      fill="white"/>
              </g>
            </svg>
            <span class="hover_text">загрузить обложку</span>
          </label>
        </media>
      </div>
    </div>
    <div class="bg" :class="{active: isBgScale}">
      <img :src="`${USER.background}`" alt="">
    </div>
  </div>
</template>

<script>
import Media from 'vue-media'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "profileHeader",
  components: {
    Media
  },
  data() {
    return {
      dataReady: false,
      isBgScale: false,
      notMyProfile: false,
      notMyFriend: false,
      friendMenuActive: false
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'AUTH'
    ])
  },
  methods: {
    ...mapActions([
        'UPLOAD_AVATAR',
        'UPLOAD_USER_BACKGROUND'
    ]),
    scalseBg: function () {
      this.isBgScale = !this.isBgScale
    },
    avatar() {
      let data = {
        'tag': 'logo',
        'file': this.$refs.avatar.files[0]
      }
      this.UPLOAD_AVATAR(data);
    },
    background() {
      let data = {
        'tag': 'background',
        'file': this.$refs.background.files[0]
      }
      this.UPLOAD_USER_BACKGROUND(data);
    },

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

</style>