import {API} from "@/Vuex/axios-config";
import {attachmentsGetFromInclude} from "@/otherJS/include-filtering";

export default {
    state: {
        tournamentGroup: null
    },

     actions: {
         async SET_TOURNAMENT_GROUP({commit, getters, dispatch}, tournamentGroupId) {
            try {
                const response = await API({
                    method: 'get',
                    url: `/groups-of-tournaments/${tournamentGroupId}?include=top-banner`
                })

                const tournamentData = response.data.data;
                const tournamentIncluded = response.data.included;

                tournamentData.topBanner = tournamentIncluded.find((included) =>
                    included.type === 'attachments'
                    && included.attributes.tag === 'top_banner'
                    && String(tournamentData.relationships['top-banner'].data.id) === String(included.id)
                ).attributes.url

                commit('SET_TOURNAMENT_GROUP', response.data)
            } catch (e) {
                console.log('SET_TOURNAMENT_GROUP', e)
                throw new Error(`SET_TOURNAMENT_GROUP ${e}`)
            }
         }
     },

    mutations: {
        SET_TOURNAMENT_GROUP(state, payload) {
            state.tournamentGroup = payload
        }
    },

    getters: {
        GET_TOURNAMENT_GROUP(state) {
            return state.tournamentGroup
        }
    }
}