import setRatingTournamentGroups from "@/otherJS/setRatingTournamentGroups";

export default {
    state: {
        ratingSingleTournamentGroup: null,
        gameDiscipline: null
    },

    actions: {
        async SET_RATING_EVENT_TOURNAMENT_GROUP({commit, dispatch}, tournamentGroupId) {
            const {data, rating} = await setRatingTournamentGroups(tournamentGroupId)

            dispatch('SET_GAME_DISCIPLINE', data)
            commit('SET_RATING_EVENT_TOURNAMENT_GROUP', rating)
        },

        SET_GAME_DISCIPLINE({commit, getters}, payload) {

            const gameId = payload.attributes['game_id'];

            const gameFromSponsor = getters.GAMES.find((game) => String(game.id) === String(gameId)).attributes.name;

            commit('SET_GAME_DISCIPLINE', gameFromSponsor)
        }
    },

    mutations: {
        SET_RATING_EVENT_TOURNAMENT_GROUP(state, payload) {
            state.ratingSingleTournamentGroup = payload
        },

        SET_GAME_DISCIPLINE(state, payload) {
            state.gameDiscipline = payload
        }
    },

    getters: {
        GET_RATING_EVENT_TOURNAMENT_GROUP(state) {
            try {
                // сортирую массив по рейтингу
                const sortedArray = state.ratingSingleTournamentGroup.sort((a, b) => Number(b.rating) - Number(a.rating));

                // Возвращаю массив с добавленным полем "place"
                return sortedArray.map((item, index) => {
                    item.place = String(index + 1)
                    return item
                })
            } catch (e) {
                return []
            }
        },

        GET_CURRENT_GAME_DISCIPLINE_IN_EVENT(state) {
            return state.gameDiscipline
        }
    }
}