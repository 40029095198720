import getMonthName from "@/otherJS/getMonthName";

const parseDateForNotifications = (myDate) => {

    const date = myDate.getDate();
    const month = getMonthName(myDate.getMonth()).slice(0, 3);
    const year = myDate.getFullYear();

    return `${date} ${month} ${year}`
}

export default parseDateForNotifications;