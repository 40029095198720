<template>
  <div class="notifications_body_block body_block">
    <div class="body_block_header">
      <div class="body_block_header_time">
        <span class="body_block_header_time_title">{{getDate}}</span>
      </div>
    </div>
    <notification-block-item v-for="(value, index) of getValue" :key="String(index)" :item="value" />
  </div>
</template>

<script>
import NotificationBlockItem
  from "@/components/Notifications/components/NotificationBlock/NotificationBlockItem/NotificationBlockItem";
import {mapGetters} from "vuex";
export default {
  name: "NotificationBlock",
  components: {NotificationBlockItem},
  props: ['notifications'],

  computed: {
    ...mapGetters([
        'GET_NOTIFICATIONS',
        'GET_CAN_SET_MORE_NOTIFICATIONS'
    ]),


    getDate() {
      return this.notifications.name
    },
    getValue() {
      return this.notifications.value
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

.notifications_body {
  .body_block {
    margin-top: 24px;

    &_header {
      display: flex;

      &_time {
        padding: 7px 8px 7px 8px;
        background-color: #3A3A3A;
        border-radius: 8px;

        &_title {
          font-size: 14px;
          line-height: 16.8px;
          opacity: .8;
          color: white;
          font-family: $blatant-cyrillic, $blatant-latin;
        }
      }
    }

    &_intersectionTrigger {
      border: 1px solid red;
    }
  }
}
</style>