const createEmailError = (error) => {
    switch (error) {
        case 'validation.unique':
            return 'На этот email уже зарегистрирован аккаунт'
        case 'validation.required':
            return 'Упс, кажется мы не получили вашу почту :( Попробуйте чуть-чуть позже'
        case 'validation.email':
            return 'Проверьте правильно ли вы ввели почту'
    }
}
const createPhoneError = (error) => {
    switch (error) {
        case 'validation.unique':
            return 'На этот телефон уже зарегистрирован аккаунт'
        case 'validation.required':
            return 'Упс, кажется мы не получили ваш телефон :( Попробуйте чуть-чуть позже'
        case 'validation.phone':
            return 'Проверьте правильно ли вы ввели телефон'
    }
}


/*
* Возвращаем текст ошибки в зависимости от типа, телефон или имэил
*/
export const createErrorByType = (type, error) => {
    switch (type) {
        case 'phone':
            return createPhoneError(error);
        case 'email':
            return createEmailError(error);
        default:
            return '';

    }
}


export const createErrorByResponse = (errors) => {
    let resultError = '';

    errors.map(error => {
        resultError += `${error.detail}<br>`;
    })

    return resultError;
}