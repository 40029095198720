<template>
  <div class="wrapper">
    <div class="modal-header"><span>Войти или зарегистрироваться</span></div>
    <div class="modal-body">
      <div class="label">
        <span class="s1">Телефон или email</span>
      </div>
      <div class="modal-input">
        <input
            ref="input"
            @keydown.enter="registration"
            class="registration__input"
            type="text"
            required
            autocomplete="on"
            placeholder="Введите свой телефон или email">
        <input
            ref="nick"
            @keydown.enter="registration"
            class="registration__input"
            type="text"
            autocomplete="off"
            placeholder="Придумайте Ник">
        <input
            ref="vk"
            @keydown.enter="registration"
            class="registration__input"
            type="text"
            autocomplete="off"
            placeholder="Ссылка на ваш профиль Vk">
        <div v-if="AUTH.errorText !== ''" class="error" v-html="AUTH.errorText"></div>
      </div>
      <div v-if="!AUTH.isRequest" class="btn__block">
        <button
            class="filled__yellow"
            @click="registration"
        >Зарегистрироваться
        </button>
        <div class="desc"><span>Нажимая кнопку «Получить код»,</span> Вы соглашаетесь c условиями
          <a href="/confidentiality_agreement.pdf" target="_blank">политики конфиденциальности</a>.
        </div>
      </div>
      <spinner v-else/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Spinner from "@/components/UIElements/spinner";

export default {
  name: "login-main",
  components: {Spinner},
  computed: {
    ...mapGetters([
      'USER',
      'AUTH'
    ])
  },
  methods: {
    ...mapActions([
      'USER_REGISTRATION'
    ]),
    registration() {
      let regData = {
        inputValue: this.$refs.input.value.toLowerCase(),
        login: this.$refs.nick.value,
        vk: this.$refs.vk.value
      }
      this.USER_REGISTRATION(regData);
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
