<template>
  <div class="timer">
    <div v-if="timerCount > 0">Отправить повторно через {{ timerCount }} сек.</div>
    <div v-else @click="emitResendAction" class="resend_code">Получить код ещё раз</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {isPhoneOrMail} from "@/otherJS/testMailOrPhoneRegExp";

export default {
  name: "countdown-timer",
  props: {
    time: {
      type: Number,
      default: 120
    },
    resendMethod: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timerCount: null
    }
  },
  computed: {
    ...mapGetters([
        'REG_CODE_EXP'
    ])
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true
    }
  },
  methods: {
    emitResendAction() {
      this.$emit('resend')
    },
    setTimerValue() {
      this.timerCount = this.REG_CODE_EXP;
    },

  },
  mounted() {
    this.setTimerValue();
  },
}
</script>

<style scoped lang="scss">
 .resend_code {
   margin: 10px 0;
   color: var(--tournament-color);
   cursor: pointer;
   text-align: center;
 }
</style>
