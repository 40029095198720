import {API} from "@/Vuex/axios-config";

const sendInviteToJoinTeam = async (teamId, userId) => {

    const accessToken = localStorage.getItem('userAccessToken');

    const data = {
        data: {
            type: "entries",
            attributes: {
                status: "pending",
                kind: "TeamToUser"
            },
            relationships: {
                sendable: {
                    data: {
                        type: "teams",
                        id: String(teamId)
                    }
                },
                receivable: {
                    data: {
                        type: "users",
                        id: String(userId)
                    }
                }
            }
        }
    }

    try {
        const response = await API({
            method: 'post',
            url: '/entries',
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        return response.data;

    } catch (e) {
        console.log(`inviteToTeam SEND_INVITE_TO_JOIN_TEAM ${e}`);
        throw new Error(`inviteToTeam SEND_INVITE_TO_JOIN_TEAM ${e}`)
    }
}

export default sendInviteToJoinTeam;