import Vue from 'vue'

const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
const months2 = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октябля', 'Ноября', 'Декабря'];
const weekday = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

Vue.filter('joinPlus', function (p) {
    if (!p) return ''
    else return p.join(' + ')
});

Vue.filter('joinComma', function (c) {
    if (!c) return ''
    else return c.join(', ')
});

Vue.filter('capitalize', function (x) {
    if (!x) return ''
    x = x.toString()
    return x.charAt(0).toUpperCase() + x.slice(1)
})

Vue.filter('regTime', function (t) {
    // формат "втр, 6 Окт 2020, 10:06"
    let a = new Date(t * 1000);
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = (a.getHours() < 10 ? '0' : '') + a.getHours();
    let min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes();
    let day = weekday[a.getDay()];
    return day + ', ' + date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
})

Vue.filter('cardTime', function (t) {
    // формат "10 Сен в 10:00"
    let a = new Date(t * 1000);
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = (a.getHours() < 10 ? '0' : '') + a.getHours();
    let min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes();
    return date + ' ' + month + ' в ' + hour + ':' + min;
})

Vue.filter('matchTime', function (t) {
    // формат "08 Сен 2020, 21:00"
    let a = new Date(t * 1000);
    let month = months[a.getMonth()];
    let year = a.getFullYear();
    let date = a.getDate();
    let hour = (a.getHours() < 10 ? '0' : '') + a.getHours();
    let min = (a.getMinutes() < 10 ? '0' : '') + a.getMinutes();
    return date + ' ' + month + ' ' + year + ', ' + hour + ':' + min;
})

Vue.filter('since', function (t) {
    // формат "10 апреля 2020г."
    // let a = new Date(t * 1000);
    let a = new Date(Date.parse(t));
    let month = months2[a.getMonth()];
    let year = a.getFullYear();
    let date = a.getDate();
    return date + ' ' + month + ' ' + year + 'г.';
})

Vue.filter('sinceShort', function (t) {
    // формат "10 апреля 2020г."
    let a = new Date(t * 1000);
    let month = months[a.getMonth()];
    let year = a.getFullYear();
    let date = a.getDate();
    return date + ' ' + month + ' ' + year;
})

Vue.filter('censor', function (x) {
    // Цензура номера телефона
    if (!x) {
        return ''
    } else {
        let arr = [...x];
        // Чек массива регуляркой и удаление пробелов
        let i = arr.length;
        while (i--) !/\S/.test(arr[i]) && arr.splice(i, 1);
        //
        let middle = Math.floor(arr.length / 2);
        let arrFirstHalf = arr.slice(0, middle);
        let arrSecondHalf = arr.slice(middle + 4, arr.length);
        let result = [arrFirstHalf.join("") + "*".repeat(4) + arrSecondHalf.join("")];
        return result.join("");
    }
})


