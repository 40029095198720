<template>
  <div class="container" @click="selectDropdownItem">
    <div class="dropdownSelectLeftSide">
      <div class="logoWrapper">
        <img :src="getLogoUrl" alt="">
      </div>

      <span class="selectTitle">{{getTeamName}}</span>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "dropdownOption",
  props: ['item'],
  methods: {
    ...mapActions([
        'SET_CLOSE_DROPDOWN_INVITE_TO_TEAM',
        'SET_ACTIVE_DROPDOWN_ITEM'
    ]),



    selectDropdownItem() {
      this.SET_ACTIVE_DROPDOWN_ITEM(this.item.id);
      this.SET_CLOSE_DROPDOWN_INVITE_TO_TEAM();
    }
  },
  computed: {
    getTeamName() {
      return this.item.attributes.name;
    },

    getLogoUrl() {
      return this.item.logo
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

.container {
  cursor: pointer;
  border-radius: 4px;
  background-color: #515151;
  padding: 8px 16px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .dropdownSelectLeftSide {
    display: flex;
    align-items: center;

    .logoWrapper {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .selectTitle {
      letter-spacing: 0.002em;
      color: white;
      font-size: 14px;
      line-height: 17px;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-weight: normal;
      margin-left: 8px;
    }
  }

  @include hover {
    background-color: lighten(#515151, 10);
  }

  &:active {
    background-color: darken(#515151, 5);
  }
}
</style>