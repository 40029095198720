<template>
  <div class="container">
    <div v-if="getDataOfTeamToUser">
      <notification-in-team :item="item"/>
    </div>

    <div v-if="getDataOfMatchBeforeStartNotification">
      <notification-in-room-match :item="item"/>
    </div>

    <div v-if="getDataOfMatchStartNotification">
      <notification-about-start-match :item="item" />
    </div>

    <div v-if="getDataOfSetScoreBySecondParticipant">
      <notification-opponent-set-score :item="item"/>
    </div>
  </div>
</template>

<script>
import NotificationInTeam from "@/components/Notifications/components/NotificationInTeam/NotificationInTeam";
import NotificationInRoomMatch
  from "@/components/Notifications/components/NotificationInRoomMatch/NotificationInRoomMatch";
import NotificationOpponentSetScore
  from "@/components/Notifications/components/NotificationOpponentSetScore/NotificationOpponentSetScore";
import NotificationAboutStartMatch
  from "@/components/Notifications/components/NotificationAboutStartMatch/NotificationAboutStartMatch";

export default {
  name: "NotificationBlockItem",
  components: {NotificationAboutStartMatch, NotificationOpponentSetScore, NotificationInRoomMatch, NotificationInTeam},
  props: ['item'],
  computed: {
    getDataOfTeamToUser() {
      return this.item.attributes['type_notification'] === 'User.Entries.TeamToUser'
    },

    getDataOfMatchBeforeStartNotification() {
      return this.item.attributes['type_notification'] === 'Match.MatchBeforeStartNotification'
    },

    getDataOfMatchStartNotification() {
      return this.item.attributes['type_notification'] === 'Match.MatchStartNotification'
    },

    getDataOfSetScoreBySecondParticipant() {
      return this.item.attributes['type_notification'] === 'Match.NotificationAboutSetScoreBySecondParticipant'
    }
  }
}
</script>

<style lang="scss" scoped>
.container {

}
</style>