import {API} from "@/Vuex/axios-config";

const getSponsorInfoForMainPage = async (SPONSOR_ID) => {
    try {
        const response = await API({
            method: 'get',
            url: `/sponsors/${SPONSOR_ID}?include=mainMiddleBannerDesktop,mainMiddleBannerTablet,mainMiddleBannerMobile,mainBottomBannerDesktop,mainBottomBannerTablet,mainBottomBannerMobile`
        })

        return response.data
    } catch (e) {
        console.log('getSponsorInfoForMainPage', e);
        throw new Error(`getSponsorInfoForMainPage ${e}`)
    }
}

export default getSponsorInfoForMainPage;