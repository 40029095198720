<template>
  <div class="container">
    <router-link
        :to="{name: 'team.name', params: {id: getTeamId}}" target="_blank"
        @click.native="closeNotifications"
        :title="getTeamName">
      <div class="avatarWrapper">
        <img :src="getLogoUrl" alt="" class="avatarWrapper__img"/>
      </div>
    </router-link>

    <div class="infoBlock">
      <div class="inviteBlock">
        <p class="inviteBlock__paragraph">Вас пригласили в команду
          <router-link
              :to="{name: 'team.name', params: {id: getTeamId}}" target="_blank"
              @click.native="closeNotifications"
              class="inviteBlock__teamName">
            «{{ getTeamName }}»
          </router-link>
        </p>
      </div>

      <div v-if="getStatusIsPending" class="inviteButtons">
        <div class="inviteButtons__accept" @click="onClickAcceptInviteToTeam"
             :class="{'inviteButtons__accept_disabled': getIsHaveTeams}">
          <span>Принять предложение</span>
        </div>
        <div class="inviteButtons__decline"
             @click="onClickDeclineInviteToTeam">
          <span>Отказать</span>
        </div>
      </div>

      <div v-else-if="getStatusIsConfirmed" class="inviteStatusAccepted">
        <div class="inviteStatusAccepted__icon">
          <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9.375L8.75 12.5L15 6.25" stroke="#E2FF31" stroke-linejoin="round"/>
          </svg>
        </div>

        <div class="inviteStatusAccepted__statusText">
          <span>Предложение принято</span>
        </div>
      </div>

      <div class="inviteTime">
        <time class="inviteTime__time">{{ getTime }}</time>
      </div>

      <div class="inviteWarning" v-if="getIsHaveTeams">
        <span>Вы уже состоите в другой команде</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import acceptInviteToTeam from "@/otherJS/acceptInviteToTeam";
import declineInviteToTeam from "@/otherJS/declineInviteToTeam";

export default {
  name: "NotificationInTeam",
  props: ['item'],
  data() {
    return {
      entryIsConfirmed: false,
    }
  },

  computed: {
    ...mapGetters([
      'USER_TEAMS',
    ]),

    getStatusIsPending() {
      if (this.entryIsConfirmed) {
        return false
      }
      return this.item.attributes.status === 'pending';
    },

    getStatusIsConfirmed() {
      return this.item.attributes.status === 'confirmed' || this.entryIsConfirmed;
    },

    getTime() {
      const dateOfNotification = new Date(this.item.attributes['createdAt']);

      let hours = dateOfNotification.getHours().toString();
      let minutes = dateOfNotification.getMinutes().toString();

      if (hours.length === 1) hours = `0${hours}`
      if (minutes.length === 1) minutes = `0${minutes}`

      return `${hours}:${minutes}`;
    },

    getTeamId() {
      return this.item.relationships.initiator.data.id
    },

    getIsHaveTeams() {
      return this.USER_TEAMS.length !== 0 && this.getStatusIsPending
    },

    getLogoUrl() {
      return this.item.teamLogoUrl
    },

    getTeamName() {
      return this.item.teamName
    }
  },

  methods: {
    ...mapActions([
      'SET_INACTIVE_MODAL_NOTIFICATIONS',
      'SET_NOTIFICATIONS',
      'GET_SINGLE_USER',
      'DELETE_CERTAIN_NOTIFICATION',
      'SET_IS_HAVE_ENTRIES_TEAM_TO_USER'
    ]),
    closeNotifications() {
      this.SET_INACTIVE_MODAL_NOTIFICATIONS();
    },
    async onClickAcceptInviteToTeam() {
      // Принимаем заявку на вступление в команду. Обновляем уведомления
      try {
        await acceptInviteToTeam(this.item.id);
        this.entryIsConfirmed = true
        await this.GET_SINGLE_USER(true)
        await this.SET_IS_HAVE_ENTRIES_TEAM_TO_USER(this.getTeamId);
      } catch (e) {
        alert('Не удалось принять приглашение в команду')
      }

    },

    async onClickDeclineInviteToTeam() {
      try {
        const response = await declineInviteToTeam(this.item.id);
        console.log('onClickDeclineInviteToTeam', response);
        this.DELETE_CERTAIN_NOTIFICATION(this.item.id);
        await this.SET_IS_HAVE_ENTRIES_TEAM_TO_USER(this.getTeamId);

        // await this.SET_NOTIFICATIONS();

      } catch (e) {
        alert('Не удалось отклонить приглашение в команду')
      }
    }
  },
}
</script>

<style lang="scss" scoped>

@import "src/scss/vars/mixins";
@import "src/scss/vars/colors";

.container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;

  .avatarWrapper {
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    border-radius: 100%;
    overflow: hidden;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .infoBlock {
    margin-left: 8px;
    flex-direction: column;
    display: flex;

    .inviteBlock {
      // justify-content: space-between;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      &__paragraph {
        font-family: $blatant-cyrillic, $blatant-latin;
        font-weight: normal;
        color: white;
        font-size: 14px;
        line-height: 17px;
      }

      &__teamName {
        text-decoration: underline;
        color: var(--tournament-color);
      }
    }

    .inviteButtons {
      margin-top: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__accept {
        cursor: pointer;
        padding: 10px 23px;
        background-color: #3A3A3A;
        letter-spacing: 0.002em;
        font-size: 14px;
        line-height: 19px;
        font-family: $blatant-cyrillic, $blatant-latin;
        font-weight: normal;
        border-radius: 4px;
        color: white;

        @include hover {
          background-color: lighten(#3A3A3A, 10);
        }

        &:active {
          background-color: darken(#3A3A3A, 1);
        }

        &_disabled {
          opacity: .6;
          pointer-events: none;
        }
      }

      &__decline {
        padding: 10px;
        margin-left: 16px;
        cursor: pointer;

        span {
          opacity: .6;
          letter-spacing: 0.2px;
          font-size: 14px;
          line-height: 120%;
          font-family: $blatant-cyrillic, $blatant-latin;
          color: white;
        }

        &_disabled {
          opacity: .6;
          pointer-events: none;
        }

      }
    }

    .inviteStatusAccepted {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 8px;

      &__icon {
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__statusText {
        margin-left: 5px;
        font-family: $blatant-cyrillic, $blatant-latin;
        font-weight: normal;
        font-size: 14px;
        line-height: 16.8px;
        color: $grey;
      }
    }

    .inviteTime {
      margin-top: 8px;

      &__time {
        color: #757575;
        font-family: $blatant-cyrillic, $blatant-latin;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .inviteWarning {
      color: #EB5757;
      font-size: 14px;
      line-height: 130%;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-weight: normal;
    }

  }
}

@media (max-width: 768px) {
  .container {
    .infoBlock {
      width: inherit;

      .inviteButtons {
        justify-content: flex-start;
      }
    }
  }
}
</style>
