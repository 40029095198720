import copyGetter from "@/otherJS/VuexHelpers/copyGetter";

export default {
    state: {
        isSimpleModalOpen: false,

        configForSimpleTextModal: {
            text: 'Ваша заявка успешно подана!',
            status: 'default',
            timeoutSeconds: 3,
        }
    },

    actions: {
        SET_SHOW_THANKS_ACTION({commit, getters, dispatch}, payload) {
            commit('SET_SHOW_THANKS_MUTATION', payload)

            const timeoutSeconds = copyGetter(getters.GET_SHOW_THANKS_CONFIG_FOR_SIMPLE_MODAL.timeoutSeconds);

            setTimeout(() => {
                dispatch('CLOSE_SHOW_THANKS_ACTION')
            }, timeoutSeconds * 1000)
        },

        CLOSE_SHOW_THANKS_ACTION({commit}) {
            commit('CLOSE_SHOW_THANKS_MUTATION')
        }
    },

    mutations: {
        SET_SHOW_THANKS_MUTATION(state, payload) {
            const {configForSimpleTextModal, isSimpleModalOpen} = payload

            state.configForSimpleTextModal = configForSimpleTextModal
            state.isSimpleModalOpen = isSimpleModalOpen
        },

        CLOSE_SHOW_THANKS_MUTATION(state) {
            state.isSimpleModalOpen = false
        }
    },

    getters: {
        GET_SHOW_THANKS_IS_SIMPLE_MODAL_OPEN(state) {
            return state.isSimpleModalOpen
        },

        GET_SHOW_THANKS_CONFIG_FOR_SIMPLE_MODAL(state) {
            return state.configForSimpleTextModal
        },
    }
}