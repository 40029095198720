<template>
  <div class="containerInviteToTeam" v-if="GET_MODAL_INVITE_TO_TEAM" @click="closeModal">
    <div class="modalContent" @click.stop="clickOnCard">
      <div v-show="!getRequestIsSent">
        <div class="modalContentCloseButton" @click="SET_INACTIVE_MODAL_INVITE_TO_TEAM">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
          </svg>
        </div>
        <div class="modalContentHeader">
          <span>Пригласить игрока в</span>
        </div>
        <div class="modalContentBody">

          <div class="dropdown">
            <div @click="toggleDropdown" class="dropdown__select">
              <div class="dropdownSelectLeftSide">
                <div class="logoWrapper">
                  <img :src="getIsSelectedLogo" alt="">
                </div>

                <span class="selectTitle">{{ getIsSelectedName }}</span>
              </div>
              <div class="dropdownSelectRightSide">
                <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6L8 10L12 6" stroke="white" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

            <div class="dropdown__options" v-show="GET_DROPDOWN_INVITE_TO_TEAM">
              <dropdown-option v-for="item in GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_NOT_SELECTED" :key="item.id"
                               :item="item"/>
            </div>
          </div>

          <div class="buttonSendRequest" @click="sendRequest">
            <span class="buttonSendRequest__text">Отправить приглашение</span>
          </div>
        </div>
      </div>

      <div v-show="getRequestIsSent" class="modalContentThanksgiving">
        <span>Ваша заявка успешно подана!</span>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownOption from "@/components/modals/inviteToTeam/dropdownOption/dropdownOption";
import {mapActions, mapGetters} from "vuex";
import sendInviteToJoinTeam from '@/otherJS/sendInviteToJoinTeam'

export default {
  name: "inviteToTeam",
  components: {DropdownOption},
  methods: {
    ...mapActions([
      'SET_INACTIVE_MODAL_INVITE_TO_TEAM',
      'SET_TOGGLE_DROPDOWN_INVITE_TO_TEAM',
      'SET_REQUEST_IS_SENT_TRUE_INVITE_TO_TEAM',
      'SET_REQUEST_IS_SENT_FALSE_INVITE_TO_TEAM',
      'SET_CLOSE_DROPDOWN_INVITE_TO_TEAM',
      'SET_CONFIG_UNIVERSAL_MODAL',
      'SET_ACTIVE_UNIVERSAL_MODAL',
      'SET_INACTIVE_UNIVERSAL_MODAL',
      'SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER'
    ]),
    clickOnCard() {
      this.SET_CLOSE_DROPDOWN_INVITE_TO_TEAM()
    },
    toggleDropdown(e) {
      e.stopPropagation();
      this.SET_TOGGLE_DROPDOWN_INVITE_TO_TEAM()
    },
    closeModal() {
      this.SET_INACTIVE_MODAL_INVITE_TO_TEAM()
    },

    async sendRequest() {
      try {
        await sendInviteToJoinTeam(this.getTeamId, this.$route.params.id);
        this.SET_REQUEST_IS_SENT_TRUE_INVITE_TO_TEAM();
        this.SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER(true)


        setTimeout(() => {
          this.closeModal();
          this.SET_REQUEST_IS_SENT_FALSE_INVITE_TO_TEAM()
        }, 2000)


      } catch (e) {
        this.SET_CONFIG_UNIVERSAL_MODAL({
          text: 'Не удалось отправить приглашение',
          status: 'warning'
        })
        this.SET_ACTIVE_UNIVERSAL_MODAL()
        setTimeout(() => {
          this.SET_INACTIVE_UNIVERSAL_MODAL()
        }, 2000)
      }
    }
  },
  computed: {
    ...mapGetters([
      'GET_DROPDOWN_INVITE_TO_TEAM',
      'GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_NOT_SELECTED',
      'GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_IS_SELECTED',
      'GET_MODAL_INVITE_TO_TEAM',
      'GET_REQUEST_IS_SENT_INVITE_TO_TEAM',
    ]),

    getRequestIsSent() {
      return this.GET_REQUEST_IS_SENT_INVITE_TO_TEAM
    },

    getIsSelectedName() {
      return this.GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_IS_SELECTED.attributes.name
    },

    getIsSelectedLogo() {
      return this.GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_IS_SELECTED.logo
    },

    getTeamId() {
      return this.GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_IS_SELECTED.id
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins.scss";
@import "src/scss/vars/colors.scss";


.containerInviteToTeam {
  z-index: 3;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);

  display: flex;
  justify-content: center;
  align-items: center;

  .modalContent {
    flex-direction: column;
    display: flex;
    position: relative;
    border-radius: 8px;
    background-color: $elemBg;
    padding: 40px;
    max-width: 375px;
    width: 100%;
    max-height: 248px;
    height: 100%;

    .modalContentCloseButton {
      cursor: pointer;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 27px;
      top: 27px;

      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .modalContentHeader {
      color: var(--tournament-color);
      letter-spacing: .2px;
      line-height: 28.8px;
      font-size: 24px;
      font-family: $blatant-cyrillic, $blatant-latin;
      font-weight: normal;
    }

    .modalContentBody {
      margin-top: 45px;
      width: 100%;

      .dropdown {
        position: relative;
        width: 100%;

        &__select {
          cursor: pointer;
          border-radius: 4px;
          background-color: #515151;
          padding: 8px 16px;
          color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;


          .dropdownSelectLeftSide {
            display: flex;
            align-items: center;

            .logoWrapper {
              width: 24px;
              height: 24px;
              border-radius: 100%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .selectTitle {
              letter-spacing: 0.002em;
              color: white;
              font-size: 14px;
              line-height: 17px;
              font-family: $blatant-cyrillic, $blatant-latin;
              font-weight: normal;
              margin-left: 8px;
            }
          }

          .dropdownSelectRightSide {
            width: 16px;
            height: 16px;

            svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          @include hover {
            background-color: lighten(#515151, 10);
          }

          &:active {
            background-color: darken(#515151, 5);
          }
        }

        &__options {
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          border-radius: 4px;
          background-color: #515151;
          width: 100%;
        }
      }

      .buttonSendRequest {
        margin-top: 16px;
        width: 100%;
        border-radius: 4px;
        background-color: var(--tournament-color);
        padding: 10px;
        text-align: center;
        cursor: pointer;

        @include hover {
          //background-color: filter(var(--tournament-color), 10);
          filter: brightness(.9);
        }

        &:active {
          //background-color: lighten(var(--tournament-color), 10);
          filter: brightness(1.1);
        }

        &__text {
          color: white;
          letter-spacing: 0.002em;
          font-size: 16px;
          line-height: 19px;
          font-family: $blatant-cyrillic, $blatant-latin;

        }
      }
    }

    .modalContentThanksgiving {

      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;

      span {
        color: var(--tournament-color);
        text-transform: uppercase;
        letter-spacing: .2px;
        line-height: 29px;
        font-family: $blatant-cyrillic, $blatant-latin;
        font-weight: normal;
        font-size: 24px;
      }
    }
  }
}
</style>