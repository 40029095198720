import WinnersService from "@/otherJS/ServiceClasses/WinnersService";


export const getPlayOffSEWinners = (matches) => {
    let winners = [];
    let firstGamers = [];
    let secondGamers = [];
    let thirdPlaceMatch = 0;

    if (matches[0].length > 1) {
        thirdPlaceMatch = matches[0].map(match => WinnersService.isThirdPlaceMatch(matches[1], match)).find(el => el !== null);
    }

    let firstPlaceMatch = matches[0].find(match => Number(match.id) !== Number(thirdPlaceMatch.id))

    WinnersService.setParticipantDataToArr(firstPlaceMatch, firstGamers)

    if (thirdPlaceMatch !== 0) {
        WinnersService.setParticipantDataToArr(thirdPlaceMatch, secondGamers)
    }

    let players = {};
    players.a = firstGamers.find(item => item.winner === true);
    players.b = firstGamers.find(item => item.winner === false);
    if (thirdPlaceMatch !== 0) {
        players.c = secondGamers.find(item => item.winner === true);
    }

    for (let place in players) {
        players[place].place = place;
        winners.push(players[place])
    }

    winners.sort((a,b) => b.place - a.place)
    return winners
}


