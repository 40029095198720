import Vue from 'vue';
import Vuex from 'vuex';

import user from "@/Vuex/modules/user";
import team from "@/Vuex/modules/team";
import tournaments from "@/Vuex/modules/tournaments";
import notifications from "@/Vuex/modules/notifications";
import search from "@/Vuex/modules/search";
import UI_Interactions from "@/Vuex/modules/UI_Interactions";
import modalInviteToTeam from "@/Vuex/modules/modalInviteToTeam";
import modalWarning from "@/Vuex/modules/modalWarning";
import universalModal from "@/Vuex/modules/universalModal";
import streams from "@/Vuex/modules/streams";
import tournamentGroups from "@/Vuex/modules/tournamentGroups";
import upcomingTournaments from "@/Vuex/modules/upcomingTournaments";
import ratingOfParticipants from "@/Vuex/modules/ratingOfParticipants";
import tgSliderBanners from "@/Vuex/modules/tgSliderBanners";
import sponsorGames from "@/Vuex/modules/sponsorGames";
import sponsorInfo from "@/Vuex/modules/sponsorInfo";
import ratingSingleTournamentGroup from "@/Vuex/modules/ratingSingleTournamentGroup";
import tournamentGroup from "@/Vuex/modules/tournamentGroup";
import eventGroupTournaments from "@/Vuex/modules/eventGroupTournaments";
import staticData from "@/Vuex/modules/staticData";
import pages from "@/Vuex/modules/pages";
import simpleTournamentModal from "@/Vuex/modules/simpleTournamentModal";
import news from "@/Vuex/modules/news";
import disciplines from "@/Vuex/modules/disciplines";
import matchScoreScreenshots from "@/Vuex/modules/matchScoreScreenshots";


Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        layout: 'default-layout'
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload
        }
    },
    actions: {},
    getters: {
        layout(state) {
            return state.layout
        }
    },
    modules: {
        staticData,
        user,
        team,
        tournaments,
        notifications,
        search,
        UI_Interactions,
        modalInviteToTeam,
        modalWarning,
        universalModal,
        streams,
        tournamentGroups,
        ratingOfParticipants,
        tgSliderBanners,
        //TODO заменил на disciplines, если не вылезет багов, можно будет просто удалить
        // sponsorGames,
        sponsorInfo,
        ratingSingleTournamentGroup,
        tournamentGroup,
        eventGroupTournaments,
        upcomingTournaments,
        pages,
        simpleTournamentModal,
        disciplines,
        news,
        matchScoreScreenshots,
    }
})

export default store;
