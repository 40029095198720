import store from "@/Vuex/store";

export default class WinnersService {

    static getParticipant(id) {
        return store.getters.PARTICIPANTS.find(item => Number(item.id) === Number(id))
    }

    static getWinnerIdInMatch(match) {
        if(Number(match.attributes.first_score) > Number(match.attributes.second_score)) {
            return Number(match.attributes.participantable_first_id);
        } else {
            return Number(match.attributes.participantable_second_id);
        }
    }

    static getRestructuredParticipantData(participant, match) {
        return {
            id: participant.id,
            attributes: participant.attributes,
            winner: WinnersService.getWinnerIdInMatch(match) === Number(participant.id)
        }
    }

    static setParticipantDataToArr (item, resultArr) {
        resultArr.push(WinnersService.getRestructuredParticipantData(WinnersService.getParticipant(item.attributes.participantable_first_id), item))
        resultArr.push(WinnersService.getRestructuredParticipantData(WinnersService.getParticipant(item.attributes.participantable_second_id), item))
    }

    static isThirdPlaceMatch(column, match) {
        if (Number(column[0].attributes.nextMatch) !== Number(match.id)) {
            return match
        }
        return null
    }
}
