import {API} from "@/Vuex/axios-config";

export default {
    state: {
        maxCountOfScreenshots: 18,
        currentParticipantScreenshots: [],
        isScreenshotLoad: false,
        currentScoreEntity: null
    },

    mutations: {
        SET_SCORE_ENTITY(state, payload) {
            state.currentScoreEntity = payload
        },

        SET_MAX_COUNT_OF_SCREENSHOTS(state, count) {
            state.maxCountOfScreenshots = count;
        },

        FILL_PARTICIPANT_SCREENSHOTS(state, screenshots) {
            state.currentParticipantScreenshots = screenshots;
        },

        ADD_SCREENSHOT_TO_STATE(state, screenshot) {
            state.currentParticipantScreenshots.push(screenshot);
        },

        CLEAR_SCREENSHOTS(state) {
            state.currentParticipantScreenshots = [];
        }
    },

    actions: {
        async UPLOAD_MATCH_SCREENSHOT({state, dispatch, rootState}, screenshot) {
            const formData = new FormData();

            formData.append('file', screenshot)
            formData.append('tag', 'attach')

            const response = await API({
                url: '/files/upload',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            });

            await dispatch('PATCH_MATCH_SCREENSHOT', response.data.data);

        },

        async PATCH_MATCH_SCREENSHOT({state, dispatch, rootState}, file) {
            try {
                await API({
                    url: `/scores/${state.currentScoreEntity.id}/relationships/attachments`,
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    },
                    data: {
                        data: [{
                            id: file.id,
                            type: 'attachments'
                        }]
                    },
                })

                await dispatch('GET_MATCH_FROM_URL', {id: rootState.tournaments.match.data.id, silently: true});
            } catch (e) {
                throw new Error(`patchScreenshotToScore ${e}`)
            }
        },

        async DELETE_MATCH_SCREENSHOT({state, dispatch, rootState}, file) {
            try {
                await API({
                    url: `/scores/${state.currentScoreEntity.id}/relationships/attachments`,
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    },
                    data: {
                        data: [{
                            id: file.id,
                            type: 'attachments'
                        }]
                    }
                });

                await dispatch('GET_MATCH_FROM_URL', {id: rootState.tournaments.match.data.id, silently: true});
            } catch (e) {
                throw new Error(`delete screenshot ${e}`)
            }
        },
    },
}
