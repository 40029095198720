<template>
  <div>
    <transition name="default">
      <router-view :key="$route.params.id"></router-view>
    </transition>
  </div>
</template>

<script>

export default {
  name: "NoContainer"
}
</script>

<style scoped lang="scss">

</style>