export default {
    state: {
        isModalOpened: false
    },

    actions: {
        SET_WARNING_MODAL_ACTIVE({commit}) {
            commit('SET_WARNING_MODAL_ACTIVE')
        },
        SET_WARNING_MODAL_INACTIVE({commit}) {
            commit('SET_WARNING_MODAL_INACTIVE')
        },
    },

    mutations: {
        SET_WARNING_MODAL_ACTIVE(state) {
            state.isModalOpened = true
        },

        SET_WARNING_MODAL_INACTIVE(state) {
            state.isModalOpened = false
        }
    },

    getters: {
        GET_WARNING_MODAL_STATUS(state) {
            return state.isModalOpened;
        }
    }
}