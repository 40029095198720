import {API} from "@/Vuex/axios-config";

export default {
    state: {
        isModalOpened: false,
        requestIsSent: false,
        dropdownIsOpened: false,
        userTeamsAsCaptainOrManager: []
    },

    actions: {
        SET_TEAMS_AS_CAPTAIN_OR_MANAGER({commit, getters}) {

            const userTeams = getters.GET_CAPTAIN_AND_MANAGED_TEAMS.map((item, index) => (
                {
                    ...item,
                    isSelected: index === 0,
                }
            ))

            commit('SET_TEAMS_AS_CAPTAIN_OR_MANAGER', userTeams);
        },

        SET_ACTIVE_MODAL_INVITE_TO_TEAM({commit}) {
            commit('SET_ACTIVE_MODAL_INVITE_TO_TEAM');
        },

        SET_INACTIVE_MODAL_INVITE_TO_TEAM({commit}) {
            commit('SET_INACTIVE_MODAL_INVITE_TO_TEAM');
        },

        SET_TOGGLE_MODAL_INVITE_TO_TEAM({commit}) {
            commit('SET_TOGGLE_MODAL_INVITE_TO_TEAM');
        },

        SET_TOGGLE_DROPDOWN_INVITE_TO_TEAM({commit}) {
            commit('SET_TOGGLE_DROPDOWN_INVITE_TO_TEAM')
        },

        SET_CLOSE_DROPDOWN_INVITE_TO_TEAM({commit}) {
            commit('SET_CLOSE_DROPDOWN_INVITE_TO_TEAM')
        },

        SET_ACTIVE_DROPDOWN_ITEM({commit, dispatch, getters}, id) {
            // копирую массив
            const data = [...getters.GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER];

            // Ставлю флаг для поиска действующего на данный момент селектора
            let flag1 = false;

            // Ставлю флаг для поиска селектора, который мы должны сделать активным
            let flag2 = false;

            // Проходим по циклу
            for (let item of data) {
                if (item.isSelected) {
                    item.isSelected = false
                    flag1 = true
                }

                if (item.id === id) {
                    item.isSelected = true
                    flag2 = true
                }

                // Если мы мутировали все значения, которые нужны - прерываем цикл
                if (flag1 && flag2) break;
            }

            commit('SET_ACTIVE_DROPDOWN_ITEM', data)
        },

        SET_REQUEST_IS_SENT_TRUE_INVITE_TO_TEAM({commit}) {
            commit('SET_REQUEST_IS_SENT_TRUE_INVITE_TO_TEAM')
        },

        SET_REQUEST_IS_SENT_FALSE_INVITE_TO_TEAM({commit}) {
            commit('SET_REQUEST_IS_SENT_FALSE_INVITE_TO_TEAM')
        },
    },

    mutations: {
        SET_TEAMS_AS_CAPTAIN_OR_MANAGER(state, data) {
          state.userTeamsAsCaptainOrManager = data
        },

        SET_ACTIVE_MODAL_INVITE_TO_TEAM(state) {
            state.isModalOpened = true
        },

        SET_REQUEST_IS_SENT_TRUE_INVITE_TO_TEAM(state) {
            state.requestIsSent = true
        },

        SET_REQUEST_IS_SENT_FALSE_INVITE_TO_TEAM(state) {
            state.requestIsSent = false
        },

        SET_INACTIVE_MODAL_INVITE_TO_TEAM(state) {
            state.isModalOpened = false
        },

        SET_TOGGLE_MODAL_INVITE_TO_TEAM(state) {
            state.isModalOpened = !state.isModalOpened
        },

        SET_TOGGLE_DROPDOWN_INVITE_TO_TEAM(state) {
            state.dropdownIsOpened = !state.dropdownIsOpened
        },

        SET_CLOSE_DROPDOWN_INVITE_TO_TEAM(state) {
            state.dropdownIsOpened = false
        },

        SET_ACTIVE_DROPDOWN_ITEM(state, data) {
            state.userTeamsAsCaptainOrManager = data;
        }
    },

    getters: {
        GET_MODAL_INVITE_TO_TEAM(state) {
            return state.isModalOpened
        },

        GET_DROPDOWN_INVITE_TO_TEAM(state) {
            return state.dropdownIsOpened
        },

        GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER(state) {
            return state.userTeamsAsCaptainOrManager
        },

        GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_IS_SELECTED(state) {
            return state.userTeamsAsCaptainOrManager.find((item) => item.isSelected)
        },

        GET_USER_TEAMS_AS_CAPTAIN_OR_MANAGER_NOT_SELECTED(state) {
            return state.userTeamsAsCaptainOrManager.filter((item) => !item.isSelected)
        },

        GET_REQUEST_IS_SENT_INVITE_TO_TEAM(state) {
            return state.requestIsSent
        }
    }
}