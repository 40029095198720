import moment from "moment";
import {secondsToDHMS} from "@/otherJS/secondsToDHMS";
import _ from "lodash";
import {mapActions, mapGetters, mapState} from "vuex";
import parseDateForNotifications from "@/otherJS/parseDateForNotifications";
import getHoursAndMinutes from "@/otherJS/getHoursAndMinutes";
import copyGetter from "@/otherJS/VuexHelpers/copyGetter";

export const tournamentInfoMixin = {
    data() {
        return {
            isModalOpened: false,
            currentComposition: null,
            showRegBtn: true,
            showDeclineEntryBtn: false,
            tournamentCardHaveActiveEntry: false,
            forceDeclineEntryBtn: false,
            timer: 0
        }
    },
    computed: {
        ...mapGetters([
            'USER_COMPOSITIONS_FOR_DISCIPLINES',
            'GET_USER_IS_AUTHENTICATE',
            'GET_TOURNAMENT_PARTICIPANT_ENTRY',
            'GET_REGISTER_AFTER_AUTH',
            'USER',
            'TOURNAMENT'
        ]),
        ...mapState({
            userManagedTeams: state => state.user.userManagedTeams,
            userCapitanTeams: state => state.user.userCaptainTeams,
            tournamentFromStore: state => state.tournaments.tournament
        }),
        getUserIsAuthenticate() {
            return this.GET_USER_IS_AUTHENTICATE
        },

        // Computed-свойство, которое проверяет нужно ли показывать кнопку
        // "учавствовать в турнире" или нет
        participateButtonStatus() {
            if (!!this.TOURNAMENT.participant_entry !== false) {
                return false;
            }
            // Создаем флаг с проверкой
            let flag = false;

            // Проверяем статус турнира. Если турнир начался, то флаг остается false.
            switch (this.getTournamentStatus(this.TOURNAMENT)) {
                case 'Открыта регистрация':
                    flag = true;
                    break;
                case 'Начнется через':
                    flag = true;
                    break;
                default:
                    break;
            }


            // Возвращаем значение результата.
            if (!this.getUserIsAuthenticate && flag) {
                return true
            } else {
                return this.isUserCanDoIt(this.TOURNAMENT);
            }  // !this.TOURNAMENT.participant_entry &&
        },

        currentTime() {
            return moment().valueOf();
        },

        // Computed-свойство, которая отслеживает момент, чтобы пользователь был
        // авторизован и данные о турнире были получены
        getUserIsAuthAndTournamentIsExist() {
            // Если мы находимся не на странице турнира, то просто возвращаем false
            if (!this.TOURNAMENT) return false

            return this.getUserIsAuthenticate
                && Object.prototype.hasOwnProperty.call(this.TOURNAMENT, "participant_entry")
                && Object.prototype.hasOwnProperty.call(this.TOURNAMENT, "attributes")
        },


    },
    watch: {
        tournamentFromStore: {
            handler (nV) {
                if (nV.participant_entry !== null) {
                    this.showRegBtn = false;
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapActions([
            'SHOW_USER_AUTH_MODALS',
            'SET_ACTIVE_UNIVERSAL_MODAL',
            'SET_CONFIG_UNIVERSAL_MODAL',
            'IS_ENTRY_CREATED',
            'CREATE_TOURNAMENT_ENTRIE',
            'NEW_IS_ENTRY_CREATED',
            'SET_PARTICIPANT_ENTRY',
            'SET_REGISTER_AFTER_AUTH_ACTION',
            'CLEAR_REGISTER_AFTER_AUTH_ACTION',
            'SET_SHOW_THANKS_ACTION',
            'GET_TOURNAMENT_BY_ID'
        ]),

        getTournamentStartedAtDiff(tournament) {
            // разница между началом турнира и сейчас
            let timeDiff = (moment(tournament.attributes.started_at).valueOf() - moment().valueOf()) / 1000;
            return secondsToDHMS(timeDiff);
        },

        getTournamentStartedAtTime(tournament) {
            const startedAt = new Date(tournament.attributes['started_at'])
            return getHoursAndMinutes(startedAt)
        },

        getTournamentStartedAtDate(tournament) {
            const startedAt = new Date(tournament.attributes['started_at'])
            return parseDateForNotifications(startedAt)
        },

        getTournamentStartedAtBySeconds(tournament) {
            return (Number(moment(tournament.attributes.started_at).format('X')));
        },
        getTournamentStartDistance(tournament) {
            return (this.getTournamentStartedAtBySeconds(tournament) - tournament.attributes.current_timestamp);
        },
        getTournamentCheckDistance(tournament) {
            return (this.getTournamentCheckInAtBySeconds(tournament) - tournament.attributes.current_timestamp);
        },
        getTournamentCheckInAtBySeconds(tournament) {
            return (moment(tournament.attributes.checkin_at).unix());
        },
        isStartedByTime(tournament) {
            // На основе getTournamentStartedAtDiff, возвращает в секундах
            return (moment(tournament.attributes.started_at).valueOf() - moment().valueOf()) / 1000;
        },
        isRegOpen(tournament) {
            return this.getTournamentStatus(tournament) === 'Открыта регистрация';
        },
        isStarted(tournament) {
            return this.getTournamentStatus(tournament) === 'Начался'
        },
        getTournamentStatus(tournament) {
            switch (tournament.attributes.status) {
                case 'before_registration':
                    return 'Начнется через'
                case 'registration':
                    return 'Открыта регистрация'
                case 'checkin':
                    return 'Проверка заявок'
                case 'before_start':
                    return 'Проверка заявок'
                case 'in_process':
                    return 'Уже идёт'
                case 'finished':
                    return 'Завершён'
            }
            // return tournament.attributes.finished_at !== null ? 'Завершён' : 'Начался'
        },
        isSponsor(tournament) {
            return tournament.attributes.partner
        },
        isPremium(tournament) {
            return tournament.attributes.sponsorship
        },
        getFormat(tournament) {
            let groupType = tournament.attributes.group_type ? tournament.attributes.group_type.split('_').join(' ') : null
            let playOffType = tournament.attributes.playoff_type ? tournament.attributes.playoff_type.split('_').join(' ') : null
            groupType = _.upperFirst(groupType);
            playOffType = _.upperFirst(playOffType);
            return _.trim([groupType, playOffType].join(' / '), ' / ');
        },
        getPlayersFormat(tournament) {
            return tournament.attributes.participant_type === 'TEAM' ? 'Командный' : 'Одиночный пользователь'
        },
        getStartRegTime(tournament) {
            return this.$options.filters.regTime((moment(tournament.attributes.start_registration_at).valueOf()) / 1000)
        },
        getEndRegTime(tournament) {
            return this.$options.filters.regTime((moment(tournament.attributes.close_registration_at).valueOf()) / 1000)
        },
        getStartedTime(tournament) {
            return this.$options.filters.regTime((moment(tournament.attributes.started_at).valueOf()) / 1000)
        },

        getRegistrationExpiresAt(tournament) {
            let timeDiff = moment(moment(tournament.attributes.close_registration_at).valueOf() - moment().valueOf()) / 1000;
            return secondsToDHMS(timeDiff);
        },
        returnTournamentStatus(tournament) {
            switch (this.getTournamentStatus(tournament)) {
                case 'Открыта регистрация':
                    return `<span class="s1">Регистрация завершится через</span>
                            <span class="s2">${this.getRegistrationExpiresAt(tournament)}</span>`
                case 'Начнется через':
                    return `<span class="s1">Начнется через</span>
                            <span class="s2">${this.getTournamentStartedAtDiff(tournament)}</span>`
                case 'Проверка заявок':
                    return `<span class="s1">Проверка заявок</span><span class="s1">Турнир начнется через</span>
                            <span class="s2">${this.getTournamentStartedAtDiff(tournament)}</span>`
                default:
                    return `<span class="s1">${this.getTournamentStatus(tournament)}</span>`
            }
        },


        isUserCanDoIt(tournamentTemp) {

            const tournament = {...tournamentTemp};

            if (tournament.attributes['participant_type'] === 'USER') {
                if (this.isRegOpen(tournament)) {
                    if (tournament.participant_entry === null || tournament.participant_entry === undefined) {
                        return true;
                    }
                }
            }

            if (tournament.attributes['participant_type'] === 'TEAM') {
                let team = this.userManagedTeams.concat(this.userCapitanTeams)[0];

                if (team) {
                    if (this.isRegOpen(tournament)) {
                        if (!!this.GET_TOURNAMENT_PARTICIPANT_ENTRY === false) {
                            return true
                        }
                    }
                }

            }

            return false;
        },

        hideRegBtn(tournament) {
            if (tournament.attributes.checkin_at === null) {
                return
            }
            this.showRegBtn = false;
        },

        updateTournament() {
            window.location.reload()
        },

        /**
         * Функция "мапер". Определяет что будет происходить при клике на кнопку "Участвовать в турнире"
         * Если человек авторизован, то будет происходить регистрация
         * Если человек не авторизован, будет вызвана модалка
         * */
        participateInTournament(tournament, e) {
            if (e) {
                e.preventDefault();
            }

            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                return this.getUserIsAuthenticate ? this.registerUser(tournament) : this.authorizeOrRegisterUser(tournament)
            }, 2000);

        },

        /**
         * Определяем может ли пользователь отменить заявку на турнир
         *
         * @param tournament - объект турнита, от которого смотрим возможность отменить заявку
         * @param force - если нужно не проверять турнир, а просто установить в false
         * */
        canUserDecline(tournament) {
            if (this.forceDeclineEntryBtn) {
                return false;
            }

            if (tournament.attributes.participant_type === 'USER') {
                if (this.isRegOpen(tournament)) {
                    console.log('tournament.participant_entry', tournament.participant_entry)
                    if (tournament.participant_entry !== null && tournament.participant_entry !== undefined) {
                        return true;
                    }
                }
            }

            if (tournament.attributes.participant_type === 'TEAM') {
                let team = this.userManagedTeams.concat(this.userCapitanTeams)[0];
                if (team !== null) {
                    if (this.isRegOpen(tournament)) {
                        if (tournament.participant_entry !== null) {
                            return true;
                        }
                    }
                }
            }

            return false;
        },

        /**
         * Функция, которая будет выполнена если пользователь не зарегестрирован.
         * Сначала будет выпадать модалка с регистрацией/авторизацией,
         * а потом будет происходить выполнение функции registerUser
         * */
        authorizeOrRegisterUser() {
            // Записываю в localStorage пометку, что после регистрации нужно будет показать модалку
            localStorage.setItem('afterAuthParticipateInTournament', 'true');

            // Показываю модалку
            this.SHOW_USER_AUTH_MODALS({isAuth: true, modal: 'password-auth'});
        },

        /**
         * Точка входа для регистрации участника на турнир
         * */
        registerUser(tournament) {
            let isNewEntry = true;
            let isEntryCreated = false

            if (tournament.attributes.participant_type === 'TEAM') {
                this.registerAsTeam(tournament, isNewEntry, isEntryCreated);
            }

            if (tournament.attributes.participant_type === 'USER') {
                this.registerAsUser(tournament, isNewEntry, isEntryCreated);
            }
        },

        registerUserInTournamentAfterAuth() {
            const registerAfterAuth = copyGetter(this.GET_REGISTER_AFTER_AUTH);

            if (registerAfterAuth) {
                this.registerUser(registerAfterAuth)
                this.CLEAR_REGISTER_AFTER_AUTH_ACTION()
            }
        },

        /**
         * Создаем заявку на одиночный турнир (single player) и отправляем её на сервер
         * */
        createAndSendSingleEntry(isNewEntry, tournament) {
            if (isNewEntry) {
                let data = {
                    'participantable_type': 'users',
                    'participantable_id': this.USER.id,
                    'tournament_id': tournament.id,
                    'usersMeta': [{
                        id: this.USER.id,
                        role: 'capitan'
                    }],
                    'usersData': [{
                        id: this.USER.id,
                        type: 'users'
                    }]
                }
                this.CREATE_TOURNAMENT_ENTRIE(data).then(() => {
                        const payload = {
                            isSimpleModalOpen: true,

                            configForSimpleTextModal: {
                                text: 'Ваша заявка успешно подана!',
                                status: 'default',
                                timeoutSeconds: 3,
                            }
                        }
                        // tournament.currentUserActiveRequest = true;
                        this.tournamentCardHaveActiveEntry = true;
                        this.showThanks(payload);
                    }).then(() => {
                        this.canUserDecline(this.TOURNAMENT);
                    });

            } else {
                const payload = {
                    isSimpleModalOpen: true,
                    configForSimpleTextModal: {
                        status: 'default',
                        timeoutSeconds: 3,
                        text: 'Вы уже подали заявку на этот турнир'
                    }
                }
                this.showThanks(payload, false);
            }
        },

        createTeamEntry(isNewEntry) {
            if (this.currentComposition === null) {
                const payload = {
                    isSimpleModalOpen: true,
                    configForSimpleTextModal: {
                        text: 'Вы уже подали заявку на этот турнир',
                        timeoutSeconds: 5,
                        status: 'default',
                    }
                }

                this.showThanks(payload);
                return;
            }

            if (isNewEntry) {
                this.showReg();
            } else {

                const payload = {
                    isSimpleModalOpen: true,
                    configForSimpleTextModal: {
                        text: 'Вы уже подали заявку на этот турнир',
                        timeoutSeconds: 5,
                        status: 'default',
                    }
                }

                this.showThanks(payload, false);
            }
        },

        declineEntry() {
            this.DECLINE_ENTRY({
                entry: {
                    id: this.TOURNAMENT.participant_entry.id
                }
            }).then(() => {

                const payload = {
                    isSimpleModalOpen: true,
                    configForSimpleTextModal: {
                        text: 'Ваша заявка успешно отозвана!',
                        timeoutSeconds: 3,
                        status: 'default',
                    }
                }
                this.showThanks(payload);

                this.forceDeclineEntryBtn = true;
                this.canUserDecline(this.TOURNAMENT);

            }).then(() => {
                let isNeededRout = !!this.$router.currentRoute.name.match(/tournaments./);

                if (isNeededRout) {
                    this.GET_TOURNAMENT_BY_ID({id: this.$router.currentRoute.params.id})
                    this.showRegBtn = true;
                }
            }).catch(({response}) => {
                const payload = {
                    isSimpleModalOpen: true,
                    configForSimpleTextModal: {
                        text: response.data.errors[0].detail,
                        timeoutSeconds: 5,
                        status: 'default',
                    }
                }

                this.showThanks(payload);
            })
        },

        showReg() {
            this.isModalOpened = true;
        },

        showThanks(payload, isRegistrationSuccessful = true) {
            this.SET_SHOW_THANKS_ACTION(payload);
            this.showRegBtn = !isRegistrationSuccessful;
        },

        /**
         * Регистрация команды на турнир
         * */
        registerAsTeam(tournament, isNewEntry, isEntryCreated) {
            let team = this.userManagedTeams.concat(this.userCapitanTeams)[0];

            this.IS_ENTRY_CREATED(tournament).then(({data}) => {
                if (team !== null) {
                    for (let i = 0; i < data.length; i++) {
                        if (Number(team.id) === Number(data[i].relationships.sendable.data.id)) {
                            isNewEntry = false;
                            isEntryCreated = true;
                            break
                        }
                    }
                    // ищем дисциплину, что бы не вносить критические изменения в "createTeamEntry"
                    for (let i = 0; i < this.USER_COMPOSITIONS_FOR_DISCIPLINES.length; i++) {
                        if (Number(this.USER_COMPOSITIONS_FOR_DISCIPLINES[i].attributes.game_id) === Number(tournament.game.id)) {
                            this.currentComposition = {...this.USER_COMPOSITIONS_FOR_DISCIPLINES[i]}
                            break
                        }
                    }
                }

                //если нет заявок:
                if (!isEntryCreated) {
                    if (this.isUserCanDoIt(tournament)) {
                        for (let i = 0; i < this.USER_COMPOSITIONS_FOR_DISCIPLINES.length; i++) {
                            if (Number(this.USER_COMPOSITIONS_FOR_DISCIPLINES[i].attributes.game_id) === Number(tournament.game.id)) {
                                this.currentComposition = {...this.USER_COMPOSITIONS_FOR_DISCIPLINES[i]}
                                isNewEntry = true
                                break
                            }
                            isNewEntry = false
                        }
                    }
                }

                this.createTeamEntry(isNewEntry);
            });
        },

        /**
         * Регистрация пользователя на одиночный турнир
         * */
        registerAsUser(tournament, isNewEntry) {
            this.IS_ENTRY_CREATED(tournament).then(({data}) => {
                data.forEach(item => {
                    if (Number(item.relationships.sendable.data.id) === Number(this.USER.id)) {
                        isNewEntry = false;
                        return isNewEntry
                    }
                })
                this.createAndSendSingleEntry(isNewEntry, tournament);
            })
        },

        setIsActiveParticipantEntry(tournament) {
            this.tournamentCardHaveActiveEntry = tournament.currentUserActiveRequest !== undefined ? tournament.currentUserActiveRequest : false;
        }
    },
}
