<template>
  <div class="profile-page">
    <div class="profile-header">
      <div class="container">
        <div class="profile-header__wrapper">
          <div class="profile-title">
            <skelet-rectangle :width="150" :height="20" />
            <skelet-rectangle :width="400" :height="40" />
          </div>
          <div class="profile-image">
            <skelet-circle :width="300" :height="300" />
          </div>
          <div class="ph-since"><skelet-rectangle :width="300" :height="20" /></div>
        </div>
      </div>
    </div>

    <div class="profile-content-wrapper">
      <div class="container profile-container">
        <!-- общее -->
        <div v-for="item in 3" :key="item" class="content-block">
          <div v-for="item in 4" :key="item" class="content-block__string mb15">
            <div class="s1"><skelet-rectangle :width="150" :height="20" /></div>
            <div class="s2"><skelet-rectangle :width="350" :height="20" /></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import skeletCircle from "@/views/sceleton/components/circle";
import SkeletRectangle from "@/views/sceleton/components/rectangle";
export default {
  name: "profileSceleton",
  components: {
    SkeletRectangle,
    skeletCircle
  }
}
</script>

<style scoped lang="scss">
.profile-header {
  height: 430px;
  margin-bottom: 16px;
}
.content-block{
  display: block;
}

</style>
