<template>
  <router-link :to="{name: 'another-profile', params: {id: player.id}}" @click.native="goToUserProfile" class="playerCard">
    <div class="playerCard__leftSide leftSide">
      <div class="leftSide__img">
        <img alt="not found" :src="player.logoUrl" />
      </div>

      <span class="leftSide__text">{{player.attributes.login}}</span>
    </div>

    <div class="playerCard__rightSide rightSide">
      <div class="rightSide__icon">
        <country-flag :country="player.attributes.country" rounded size="normal"/>
      </div>
    </div>

  </router-link>
</template>

<script>

import CountryFlag from 'vue-country-flag'
import {mapActions} from "vuex";

export default {
  name: "searchPlayerBlock",
  props: ['player'],
  components: {CountryFlag},
  computed: {

  },
  methods: {
    ...mapActions([
      'SET_SHOW_DROPDOWN',
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN',
      'SET_STATUS_MOBILE_NAV'
    ]),
    goToUserProfile() {
      this.SET_STATUS_MOBILE_NAV(false);
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
    }
  }
}

</script>

<style lang="scss" scoped>
  .playerCard {
    cursor: pointer;
    width: 100%;
    margin-top: 8px;
    padding: 6px 8px;
    background-color: #3A3A3A;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-radius: 4px;

    &__leftSide, .leftSide {
      display: flex;
      align-items: center;
      flex-direction: row;

      &__img {
        height: 28px;
        width: 28px;

        border-radius: 100%;
        overflow: hidden;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      &__text {
        font-weight: normal;
        font-size: 14px;
        line-height: 16.8px;
        font-family: 'SF UI Text', serif;
        color: white;
        margin-left: 8px;
      }
    }

    &__rightSide, .rightSide {

    }

    &:hover {
      background-color: lighten(#3A3A3A, 10);
    }
  }
</style>