import {API} from '../axios-config';
import router from "@/router";
import FetchingData from "@/otherJS/FetchingData";
import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import user from "@/Vuex/modules/user";

export default {
    state: {
        isTeamRequest: true,
        isManagerPOV: false,
        isTeamMemberPOV: false,
        isEntriesRequest: false,
        team: {
            attributes: {
                personalWebsite: '',
                name: '',
                disciplines: [],
                country: '',
                description: '',
            }
        },
        teamGames: [],
        users: [],
        teamEntries: [],
        activeTeamComposition: 0,
        compositionUsersForTournamentRegistration: null
    },
    mutations: {
        SET_TEAM_TO_STATE: (state, data) => {
            state.team = {
                attributes: {
                    personalWebsite: '',
                    name: '',
                    disciplines: [],
                    country: '',
                    description: '',
                }
            }
            state.users = []
            state.teamGames = []

            state.team = data.team;
            state.users = data.users;
            state.teamGames = data.games;
        },

        SET_TEAM_REQUEST: (state, isRequest) => {
            state.isTeamRequest = isRequest;
        },
        SET_ENTRIES_REQUEST: (state, isRequest) => {
            state.isEntriesRequest = isRequest;
        },
        SET_TEAM_MEMBER: (state, member) => {
            state.isTeamMemberPOV = member;
        },
        SET_TEAM_MANAGER: (state, manager) => {
            state.isManagerPOV = manager;
        },
        SET_TEAM_ENTRIES: (state, entries) => {
            state.teamEntries = entries;
        },
        SET_TEAM_LOGO: (state, logo) => {
            state.team.logo = logo;
            let currentSessionItem = JSON.parse(localStorage.getItem('user'));
            currentSessionItem.managedTeams[0].logo = logo;
            sessionStorage.setItem('user', JSON.stringify(currentSessionItem));
        },
        SET_TEAM_BACKGROUND: (state, background) => {
            state.team.background = background;
            let currentSessionItem = JSON.parse(sessionStorage.getItem('user'));
            currentSessionItem.managedTeams[0].background = background;
            sessionStorage.setItem('user', JSON.stringify(currentSessionItem));
        },


        SET_ACTIVE_TEAM_TAB: (state, activeTab) => {
            state.activeTeamComposition = activeTab
        },

        SET_USERS_FOR_TOURNAMENT_REGISTRATION: (state, users) => {
            state.compositionUsersForTournamentRegistration = users
        }
    },
    actions: {
        UPDATE_TEAM_GAMES({commit, getters}, data) {
            return API({
                url: `/teams/${data.teamId}/relationships/games`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': data.games
                }
            })
        },

        async CREATE_TEAM({commit, dispatch}, data) {
            commit('SET_TEAM_REQUEST', true);
            const res = await API({
                url: '/teams',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    data: {
                        type: 'teams',
                        attributes: {...data.team},
                        relationships: {
                            'games': {
                                'data': data.games
                            }
                        }
                    }
                }
            });
            commit('SET_TEAM_REQUEST', false);
            console.log(res.data.data)
            const team = res.data.data
            router.push(`/teams/${team.id}`)
        },
        UPDATE_TEAM_DATA({commit, dispatch}, data) {
            return API({
                url: `/teams/${data.teamId}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'teams',
                        'id': data.teamId.toString(),
                        'attributes': {...data.attributes},
                        'relationships': {
                            'games': {
                                'data': data.games
                            }
                        }
                    }
                }
            }).then(() => {
                dispatch('UPDATE_TEAM', {refresh: true, entries: false})
            })

        },
        DELETE_TEAM({commit, dispatch}, data) {
            return API({
                url: `/teams/${data.teamId}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'teams',
                        'id': data.teamId.toString(),
                        'attributes': {...data.attributes}
                    }
                }
            }).then((res) => {
            })
        },

        GET_TEAM({commit, getters, dispatch}, data) {
            commit('SET_TEAM_REQUEST', true);
            commit('SET_TEAM_MANAGER', false);
            commit('SET_TEAM_MEMBER', false);
            let getData = new FetchingData({
                url: `${data.url}`,
                params: '?include=logo,background,users,games,games.logo,compositionsForDisciplines',
                method: 'GET',
            });

            getData.createTeam().then(data => {
                commit('SET_TEAM_TO_STATE', data)

                for (let i = 0; i < data.users.length; i++) {
                    let user = data.users[i];
                    if (Number(user.id) === Number(getters.USER.id)) {
                        commit('SET_TEAM_MEMBER', true)
                        if (user.role === 'manager') {
                            commit('SET_TEAM_MANAGER', true);
                            break
                        }

                    }
                }

                // получить энтрисы с фильтром по юзеру, если он не член команды
                if (!getters.MEMBER_POV && getters.USER.id) {
                    dispatch('GET_USER_ENTRIESES_TO_TEAM', {teamId: data.team.id})
                }

            }).then(() => {
                commit('SET_TEAM_REQUEST', false);
            })
        },
        UPDATE_TEAM({commit, getters, dispatch}, flags) {
            if (flags.refresh) {
                commit('SET_TEAM_REQUEST', true);
            }
            let getData = new FetchingData({
                url: `/teams/${getters.TEAM.id}`,
                params: '?include=logo,background,users.logo,games,games.logo,compositionsForDisciplines',
                method: 'GET',
            });

            getData.createTeam().then(data => {
                commit('SET_TEAM_TO_STATE', data)
                if (flags.entries) {
                    dispatch('GET_TEAM_ENTRIES', {id: getters.TEAM.id});
                }
            }).then(() => commit('SET_TEAM_REQUEST', false))
        },

        GET_TEAM_ENTRIES({commit}, data) {
            commit('SET_ENTRIES_REQUEST', true)
            API({
                url: `/entries?include=sendable&filter[receivable_id]=${data.id}&filter[receivable_type]=teams&filter[status]=pending`,
                method: 'GET',
            })
                .then(response => {
                    /* TODO Возможно надо переделать формирование юзеров для заявок и команды в 1 метод */
                    if (response.data.data.length) {
                        let entryUsers = includeFilteringByType(response.data.included, 'users').filter(item => {

                            let itemEntrie = response.data.data.filter(entrie => {
                                if (entrie.relationships.sendable.data.id === item.id) {
                                    return entrie.relationships.sendable.data.id
                                }
                            })[0];
                            item.entrie = {
                                'id': itemEntrie.id,
                                'kind': itemEntrie.attributes.kind,
                                'status': itemEntrie.attributes.status,
                                etc: itemEntrie.attributes.etc
                            }
                            return item;
                        })
                        commit('SET_TEAM_ENTRIES', entryUsers)
                    } else {
                        commit('SET_TEAM_ENTRIES', [])
                    }
                    commit('SET_ENTRIES_REQUEST', false)
                })
        },
        CREATE_TEAM_ENTRIE({commit, getters, dispatch}) {
            commit('SET_ENTRIES_REQUEST', true)
            API({
                url: `/entries`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": {
                        "type": "entries",
                        "attributes": {
                            "status": "pending",
                            "kind": "toTeam",
                            "etc": {
                                "game_id": Number(getters.TEAM_GAMES[getters.ACTIVE_TEAM_TAB].id)
                            }
                        },
                        "relationships": {
                            "sendable": {
                                "data": {
                                    "type": "users",
                                    "id": getters.USER.id.toString()
                                }
                            },
                            "receivable": {
                                "data": {
                                    "type": "teams",
                                    "id": getters.TEAM.id.toString()
                                }
                            }
                        }
                    }
                }
            }).then(() => {
                commit('SET_ENTRIES_REQUEST', false);
                commit('SET_USER_REQUEST_TO_TEAM', true);

                dispatch('UPDATE_TEAM', {refresh: false, entries: true});
            })
        },
        UPDATE_TEAM_ENTRY({commit, dispatch, getters}, data) {
            API({
                url: `/entries/${data.entry_id}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": {
                        "type": "entries",
                        "id": data.entry_id,
                        "attributes": {
                            "status": data.status,
                            "etc": {game_id: data.game_id}
                        }
                    }
                }
            }).then(() => {
                dispatch('UPDATE_TEAM', {entries: true, refresh: true})
            }).catch(error => {
                console.log('update entry err', error)
                console.dir(error)
            })
        },

        async DELETE_USER_FROM_TEAM({commit, getters, dispatch}, data) {
            const res = await API({
                url: `/teams/${data.team_id}/relationships/users`,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    "data": [{
                        "type": "users",
                        "id": data.user_id,
                    }]
                }
            })

            await dispatch('UPDATE_TEAM', {refresh: false, entries: false});
            commit('SET_TEAM_MEMBER', false)
            return true
        },
        CHANGE_DISCIPLINE_COMPOSITION({commit, dispatch}, dataToChange) {
            let url, data;
            switch (dataToChange.operation) {
                case 'delete':
                    url = '/relationships/users'
                    data = {
                        "data": [{
                            "type": "users",
                            "id": dataToChange.user_id,
                        }]
                    }
                    break;
                case 'update':
                    url = '';
                    data = {
                        "data": {
                            "type": "compositions-for-disciplines",
                            "id": `${dataToChange.composition_id}`,
                            "relationships": {
                                "users": {
                                    "data": dataToChange.usersData,
                                    "meta": {
                                        "roles": dataToChange.users
                                    },
                                }
                            }
                        }
                    }
            }

            return API({
                url: `/compositions-for-disciplines/${dataToChange.composition_id}${url}`,
                method: dataToChange.method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: data
            }).then(() => {
                dispatch('UPDATE_TEAM', {refresh: false, entries: false})
            })
        },

        UPLOAD_TEAM_LOGO({commit, dispatch}, data) {
            let formData = new FormData();
            formData.append('file', data.file)
            formData.append('tag', data.tag)
            return API({
                url: `/files/upload`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            }).then(res => {
                dispatch('PATCH_TEAM_LOGO', {id: res.data.data.id})
                commit('SET_TEAM_LOGO', res.data.data.attributes.url)
            })
        },
        PATCH_TEAM_LOGO({commit, getters}, data) {
            return API({
                url: `/teams/${getters.TEAM.id}/relationships/logo`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'attachments',
                        "id": data.id
                    }
                }
            })
        },

        UPLOAD_TEAM_BACKGROUND({commit, dispatch}, data) {
            let formData = new FormData();
            formData.append('file', data.file)
            formData.append('tag', data.tag)
            return API({
                url: `/files/upload`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
                .then(res => {
                    dispatch('PATCH_TEAM_BACKGROUND', {id: res.data.data.id})
                    commit('SET_TEAM_BACKGROUND', res.data.data.attributes.url)
                })
        },
        PATCH_TEAM_BACKGROUND({commit, getters}, data) {
            return API({
                url: `/teams/${getters.TEAM.id}/relationships/background`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'attachments',
                        "id": data.id
                    }
                }
            })
                .then(res => {
                })
        },

        async GET_COMPOSITION_USERS({commit}, dataToGetUsers) {
            const res = await API({
                url: `/compositions-for-disciplines/${dataToGetUsers.composition_id}?include=users.logo`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
            })
            const resData = res.data;

            let logos = includeFilteringByTag(resData.included, 'logo');
            let users = includeFilteringByType(resData.included, 'users')
            users.map(user => {
                user.logo = logos.filter(logo => logo.id === user.relationships.logo.data.id)[0].attributes.url;
                user.role = res.data.data.relationships.users.meta.roles.find(el => Number(el.id) === Number(user.id)).role
            });

            commit('SET_USERS_FOR_TOURNAMENT_REGISTRATION', users)
        }

    },
    getters: {
        TEAM(state) {
            return state.team;
        },
        GET_TEAM_IS_DELETED(state) {
            try {
                return Boolean(state.team.attributes.deletedAt)
            } catch (e) {
                return false
            }
        },
        TEAM_USERS(state) {
            return state.users;
        },
        TEAM_GAMES(state) {
            return state.teamGames;
        },
        TEAM_ENTRIES(state) {
            return state.teamEntries;
        },
        TEAM_REQUEST(state) {
            return state.isTeamRequest;
        },
        TEAM_ENTRIES_REQUEST(state) {
            return state.isEntriesRequest;
        },
        MANAGER_POV(state) {
            return state.isManagerPOV;
        },
        MEMBER_POV(state) {
            return state.isTeamMemberPOV;
        },
        ACTIVE_TEAM_TAB(state) {
            return state.activeTeamComposition;
        },
        USERS_FOR_TOURNAMENT_REGISTRATION(state) {
            return state.compositionUsersForTournamentRegistration;
        }
    },
}
