<template>
  <div class="wrapper">
    <div class="modal-header">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="GO_BACK">
        <path
            d="M32 28C32 30.2091 30.2091 32 28 32L4 32C1.79086 32 0 30.2091 0 28L0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28Z"
        />
        <path d="M19 9L12 16L19 23" stroke="#B0B0B0" stroke-width="1.5" stroke-linejoin="round"/>
        <path
            d="M28 31L4 31V33H28V31ZM1 28L1 4H-1V28L1 28ZM4 1L28 1V-1H4V1ZM31 4L31 28H33V4H31ZM28 1C29.6569 1 31 2.34315 31 4H33C33 1.23858 30.7614 -1 28 -1V1ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM4 31C2.34315 31 1 29.6569 1 28L-1 28C-1 30.7614 1.23858 33 4 33V31ZM28 33C30.7614 33 33 30.7614 33 28H31C31 29.6569 29.6569 31 28 31V33Z"
            fill="#B0B0B0"/>
      </svg>
      <span>Введите код</span>
    </div>
    <div class="modal-body">
      <div class="label">
        <span class="s1">Новый пароль</span>
      </div>
      <label class="modal-input input__label">
        <input
            ref="newPassword"
            :type="passwordType"
            placeholder="Введите новый пароль"
            required
        >
        <svg v-if="!isPassShowed" @click="showHidePass" class="show__pass" width="18" height="18" viewBox="0 0 24 24"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <title>Показать текст пароля</title>
          <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="#fafafa"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
              stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else @click="showHidePass" class="show__pass" width="18" height="18" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <title>Скрыть текст пароля</title>
          <path
              d="M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003L17.94 17.94Z"
              stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 1L23 23" stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </label>

      <div class="label">
        <span class="s1">{{ AUTH.authType === 'phone' ? 'Номер' : 'Почта' }} получателя:</span>
        <span class="s2">{{ AUTH.authType === 'phone' ? USER.attributes.phone : USER.attributes.email }}</span>
      </div>
      <div class="modal-input">
        <input
            ref="codeInput"
            @keydown.enter="verifyUser"
            type="text"
            :placeholder=this.placeholder
            required
        >
        <div v-if="AUTH.errorText !== ''" class="error" v-html="AUTH.errorText"></div>
        <countdown-timer v-if="!AUTH.isRequest" @resend="resendCode" />
      </div>
      <div class="btn__block">
        <button v-if="!AUTH.isRequest" class="filled__yellow" @click="verifyUser">Отправить</button>
        <spinner v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Spinner from "@/components/UIElements/spinner";
import CountdownTimer from "@/components/UIElements/countdown-timer";
import {isPhoneOrMail} from "@/otherJS/testMailOrPhoneRegExp";

export default {
  name: "confirm-recover",
  components: {CountdownTimer, Spinner},
  data() {
    return {
      placeholder: '',
      isPassShowed: false,
      passwordType: 'password'
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'AUTH'
    ])
  },
  methods: {
    ...mapActions([
      'CONFIRM_PASSWORD_RECOVER',
      'GO_BACK',
      'RECOVER_PASSWORD'
    ]),
    showHidePass() {
      this.isPassShowed = !this.isPassShowed;
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    verifyUser() {
      let confirmData = {
        code: this.$refs.codeInput.value,
        password: this.$refs.newPassword.value
      }
      this.CONFIRM_PASSWORD_RECOVER(confirmData);
    },
    resendCode() {
      if (this.USER.attributes.phone !== undefined) {
        let data = {
          type: isPhoneOrMail(this.USER.attributes.phone),
          inputValue: this.USER.attributes.phone
        }
        this.RECOVER_PASSWORD(data)
      }
      if (this.USER.attributes.email !== undefined) {
        console.log('resend email')

        let data = {
          type: isPhoneOrMail(this.USER.attributes.email),
          inputValue: this.USER.attributes.email.toLowerCase()
        }
        this.RECOVER_PASSWORD(data)
      }
    }
  },
  mounted() {
    this.placeholder = this.AUTH.authType === 'phone' ? 'Введите код из смс' : 'Введите код из email'
  }
}
</script>

<style scoped>

</style>
