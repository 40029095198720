import {API} from "@/Vuex/axios-config";
import filterForEventGroups from "@/otherJS/filterForEventGroups";
import validateEventGroupTournaments from "@/otherJS/validateEventGroupTournaments";

// public const INDEXES_OF_STATUSES = [
//     self::STATUS_BEFORE_REGISTRATION => 0,
//     self::STATUS_REGISTRATION        => 1,
//     self::STATUS_CHECKIN             => 2,
//     self::STATUS_BEFORE_START        => 3,
//     self::STATUS_IN_PROCESS          => 4,
//     self::STATUS_FINISHED            => 5,
// ];

export default {
    state: {
        eventGroupsTournaments: null,
        selectedFilter: {
            name: 'all',
            value: null
        },
        selectedSort: 'started_at',
        typeOfSort: '-',  // - по убыванию, + по возрастанию
        countOfAllTournaments: null,
    },

    actions: {
        async SET_EVENT_GROUPS_TOURNAMENTS({commit, getters, dispatch}, groupOfTournamentId) {
            try {
                const selectedFilter = getters.GET_SELECTED_FILTER_FOR_EVENTS;
                const selectedSort = getters.GET_SELECTED_SORT_FOR_EVENTS;
                const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;

                const response = await API(filterForEventGroups(selectedFilter, selectedSort, typeOfSort, groupOfTournamentId))

                if (selectedFilter.name === 'all')
                    dispatch('SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS', response.data.meta.page.total)

                const result = validateEventGroupTournaments(response.data)

                result.data = await dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments: result.data})

                commit('SET_EVENT_GROUPS_TOURNAMENTS', result)
            } catch (e) {
                console.log('SET_EVENT_GROUPS_TOURNAMENTS', e)
                throw new Error(`SET_EVENT_GROUPS_TOURNAMENTS ${e}`)
            }
        },
        
        async SET_MORE_EVENT_GROUPS_TOURNAMENTS({commit, getters, dispatch}, groupOfTournamentId) {
            try {
                const selectedFilter = getters.GET_SELECTED_FILTER_FOR_EVENTS;
                const selectedSort = getters.GET_SELECTED_SORT_FOR_EVENTS;
                const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;
                const nextPage = Number(getters.GET_EVENT_GROUPS_META.page['current-page']) + 1

                const response = await API(filterForEventGroups(selectedFilter, selectedSort, typeOfSort, groupOfTournamentId, nextPage))

                const result = validateEventGroupTournaments(response.data)

                result.data = await dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments: result.data})

                commit('SET_MORE_EVENT_GROUPS_TOURNAMENTS', result);

            } catch (e) {
                console.log('SET_MORE_EVENT_GROUPS_TOURNAMENTS', e);
                throw new Error(`SET_MORE_EVENT_GROUPS_TOURNAMENTS ${e}`)
            }
        },

        SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({commit}, payload) {
            commit('SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS', payload)
        },

        SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS({commit}, payload) {
            commit('SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS', payload)
        },

        SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS({commit}, payload) {
            commit('SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS', payload)
        },

        TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS({commit, getters}) {
            const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;

            if (typeOfSort === '-') commit('SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS', '+')
            else commit('SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS', '-')
        }
    },

    mutations: {
        SET_EVENT_GROUPS_TOURNAMENTS(state, payload) {
            state.eventGroupsTournaments = payload
        },

        SET_MORE_EVENT_GROUPS_TOURNAMENTS(state, payload) {
            const tempState = {...state.eventGroupsTournaments};

            tempState.meta = {...payload.meta};
            tempState.included = [...tempState.included, ...payload.included];
            tempState.data = [...tempState.data, ...payload.data];

            state.eventGroupsTournaments = tempState;
        },

        SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.selectedFilter = payload
        },

        SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS(state, payload) {
            state.countOfAllTournaments = payload
        },

        SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.selectedSort = payload
        },

        SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.typeOfSort = payload
        },
    },

    getters: {
        GET_EVENT_GROUPS_TOURNAMENTS(state) {
            try {
                return state.eventGroupsTournaments.data
            } catch (e) {
                return []
            }
        },

        GET_IS_HAVE_GROUPS_TOURNAMENTS(state) {
            return state.eventGroupsTournaments
        },

        GET_IS_ALL_DATA_LOADED(state) {
            try {
                const currentPage = state.eventGroupsTournaments.meta.page['current-page'];
                const lastPage = state.eventGroupsTournaments.meta.page['last-page'];

                return currentPage === lastPage;

            } catch (e) {
                return true
            }
        },

        GET_EVENT_GROUPS_META(state) {
            try {
                return state.eventGroupsTournaments.meta
            } catch (e) {
                return []
            }
        },

        GET_SELECTED_FILTER_FOR_EVENTS(state) {
            return state.selectedFilter
        },

        GET_SELECTED_SORT_FOR_EVENTS(state) {
            return state.selectedSort
        },

        GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS(state) {
            return state.countOfAllTournaments
        },

        GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS(state) {
            return state.typeOfSort
        }
    }
}