// Функция, которая запоминает, нужно ли показывать предупреждающую модалку после перезагрузки страницы
// import {vkOnLoad} from "@/otherJS/socialsRegistration/vk";

// Коллбэк, который будет осуществляться после загрузки страницы
const doAllThings = () => {

}

const onLoadListener = () => {
    window.addEventListener('load', doAllThings)
}

const destroyLoadListener = () => {
    window.removeEventListener('load', doAllThings)
}

export {onLoadListener, destroyLoadListener};