<template>
  <div
      class="circle skelet-anim"
      :style="{
        height: height + 'px',
        width: width + 'px'
      }"
  ></div>
</template>

<script>
export default {
  name: "skelet-circle",
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
  .circle {
    border-radius: 50%;
    background-color: darken(#3A3A3A, 3%);
  }
</style>