<template>
  <div class="wip">
    <div class="bg" ref="bg_1"></div>
    <div class="content">
      <div class="spray-container">
        <img src="~@/img/spay_tech.page.png" class="spray" alt="" ref="spray_1">
      </div>
      <svg ref="title_1" class="svg-title" width="747" height="103" viewBox="0 0 747 103" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
            d="M203.724 37.9097H60.4024C32.8818 37.9097 32.3456 34.1545 32.3456 30.0417C32.3456 25.3924 40.2087 23.9618 54.6838 23.9618H198.363C215.519 23.9618 232.853 32.3663 232.853 32.3663L241.788 10.3715C241.788 10.3715 225.169 0 198.899 0H55.0412C25.1974 0.357645 7.50562 7.86806 7.50562 30.0417C7.50562 43.6319 15.3686 61.8715 50.5736 61.8715H194.074C219.986 61.8715 221.595 64.9115 221.595 69.7396C221.595 75.8194 212.838 78.8594 198.363 78.8594H54.5051C35.5623 78.8594 10.901 66.6997 10.901 66.6997L0 88.3368C0 88.3368 25.1974 103 54.8625 103H198.899C227.313 103 246.435 89.5885 246.435 69.9184C246.435 49.3542 229.994 37.9097 203.724 37.9097Z"
            fill="#272727"/>
        <path
            d="M539.579 47.0295H481.857V69.9184H506.339C500.978 76.1771 492.043 79.3958 479.534 79.3958H318.699C296.003 79.3958 284.924 72.4219 284.924 51.5C284.924 30.7569 296.003 23.6042 318.699 23.6042H479.534C505.803 23.6042 516.347 33.9757 516.347 33.9757L534.754 18.776C534.754 18.776 517.598 0.178817 479.534 0H318.699C283.673 0 258.297 20.0278 258.297 51.5C258.297 83.151 283.673 103 318.699 103H479.534C492.222 103 503.838 99.9601 513.666 94.059V101.391H539.579V47.0295Z"
            fill="#272727"/>
        <path d="M577.409 78.6806V1.43056H551.496V101.391H747V78.6806H577.409Z" fill="#272727"/>
      </svg>
      <div class="launch">запуск платформы</div>
      <div class="date">ожидается</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unavailable",
  mounted() {
    const block_1 = this.$refs.bg_1;
    const block_2 = this.$refs.spray_1;
    const block_3 = this.$refs.title_1;

    onmousemove = (e) => {
      const x = innerWidth / 2 - e.x
      const y = innerHeight / 2 - e.y
      block_1.style.transform = `translate(${-x / 600}px, ${-y / 600}px)`
      block_2.style.transform = `translate(${x / 450}px, ${y / 450}px)`
      block_3.style.transform = `translate(${-x / 550}px, ${-y / 550}px)`
    }
  }
}
</script>

<style scoped lang="scss">
.wip {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(39, 39, 39, 0.95), rgba(39, 39, 39, 0.95)), url(~@/img/main_bg.jpg) no-repeat center;
    background-size: cover;
    filter: blur(5px);
  }

  &:before {
    content: '';
    width: 100%;
    flex: 2;
  }

  & > * {
    flex: 5;
  }

  .spray {
    width: auto;
    height: 715.97px;
    vertical-align: middle;
    @media (max-width: 525px) {
      height: 500px;
    }

    &-container {
      flex: unset;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translateY(-85%) translateX(-45%);
      display: block;
    }
  }

  .content {
    flex-flow: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > * {
      z-index: 1;
    }

    .svg-title {
      //margin-bottom: 150px;
      margin-bottom: 15vh;
      @media (max-width: 992px) {
        width: 80%;
      }
      @media (max-width: 747px) {
        width: 80%;
      }
    }

    .launch {
      margin-bottom: 6px;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 10px;
      text-transform: uppercase;
      color: #AFAFAF;
      position: relative;
      font-family: 'Blatant UltraWeight', serif;
      text-align: center;
      display: inline;
      @media (max-width: 1140px) {
        max-width: fit-content;
      }
      @media (max-width: 525px) {
        font-size: 14px;
        max-width: 290px;
      }


      &:before, &:after {
        content: '';
        position: absolute;
        max-width: 268px;
        width: 100%;
        height: 1px;
        top: calc(50% - 1px);
        background: linear-gradient(90deg, rgba(234, 234, 234, 0) 0%, #EAEAEA 99.81%);
        right: calc(100% + 15px);
        animation: wip 3s infinite cubic-bezier(0.83, 0, 0.17, 1);

      }

      &:after {
        transform: rotate(180deg);
        right: unset;
        left: calc(100% + 15px);
      }
    }

    .date {
      font-size: 12px;
      line-height: 1.2;
      text-transform: uppercase;
      color: #757575;
      font-family: 'BlatantCyrillic';
      text-align: center;
      letter-spacing: 0.2px;
    }
  }
}

@keyframes wip {
  0% {
    opacity: 1;
    width: 0;
  }
  20% {
    opacity: 1;
    width: 0;
  }
  70% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
</style>