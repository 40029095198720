import {mapActions, mapState} from "vuex";
import ParticipantService from "@/otherJS/ServiceClasses/ParticipantService";
import parseDateForNotifications from "@/otherJS/parseDateForNotifications";
import getHoursAndMinutes from "@/otherJS/getHoursAndMinutes";

export const notificationsMixin = {
    data() {
        return {
            notificationIsLoaded: false,
            opponent: {
                name: null,
            },
            match: {
                id: null,
                startTime: null
            },
            notificationTime: null
        };
    },
    computed: {
        ...mapState({
            currentUser: state => state.user.user
        }),
    },
    methods: {
        ...mapActions([
            'GET_OPPONENT_IN_NOTIFICATION'
        ]),

        async getOpponent() {

            const opponentId = Number(this.item.match.attributes.participantable_first_id) === Number(this.currentUser.id) ? this.item.match.attributes.participantable_second_id : this.item.match.attributes.participantable_first_id;
            const participantableType = this.item.match.attributes.participantable_first_type;

            const opponentInNotification = await this.GET_OPPONENT_IN_NOTIFICATION({
                id: opponentId,
                type: participantableType
            });

            this.opponent = ParticipantService.formattingParticipantStructure(opponentInNotification);

        },

        getMatchData() {
            this.match.id = this.item.attributes.data.data['match_id'];
            const startedAt = new Date(this.item.match.attributes['started_at']);
            this.match.startTime = `${parseDateForNotifications(startedAt)}, ${getHoursAndMinutes(startedAt)}`;
        },

        getNotificationTime() {
            const createdAt = new Date(this.item.attributes['createdAt']);
            this.notificationTime = `${getHoursAndMinutes(createdAt)}`;
        },

        initNotificationData() {
            this.getOpponent();
            this.getMatchData();
            this.getNotificationTime();

            this.notificationIsLoaded = true;
        }
    },

    mounted() {
        if (!!this.item.match === false) {
            return
        }
        this.initNotificationData();
    }
}
