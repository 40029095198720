import {API} from "@/Vuex/axios-config";
import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";

/**
 * Класс для получения данных по турниру / турнирам
 * */
export default class TournamentRepository {
    /**
     * Получаем турнир по id, с переданными параметрами
     * @param id - id турнира
     * @param include - что нужно захватить с турниром с бека
     * */
    static async getById(id, include = null) {
        let url = `/tournaments/${id}`

        if (include !== null) {
            url += (`?include=` + include.join(','))
        }

        const res = await API({
            url,
            method: "GET"
        });

        let tournament = res.data.data;
        const included = res.data.included;

        /*include.map(prop => {
            tournament[prop] = includeFilteringByType(included, prop);
        })*/

        if (include.filter(el => el === 'game').length && include.filter(el => el === 'background').length) {

            // TODO пока что оставляю так, потому что возникла сложность с аттачментами. за неимением времени отложил
            let games = includeFilteringByType(included, 'games');
            let backgrounds = includeFilteringByTag(included, 'background');
            tournament.background = backgrounds.filter(item => item.id === tournament.relationships.background.data.id)[0].attributes.url;
            tournament.game = games.filter(item => item.id === tournament.relationships.game.data.id)[0];

        }


        return tournament;
    }
}