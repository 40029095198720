import {API} from "@/Vuex/axios-config";
import parseDateForNotifications from "@/otherJS/parseDateForNotifications";
import parseNotifications from "@/otherJS/parseNotifications";
import getNotificationsWithIncludedInfo from "@/otherJS/getNotificationsWithIncludedInfo";

export default {
    state: {
        isModalOpened: false,
        canApplyStyles: false,
        notifications: null,
        teamsForNotifications: null,
        usersForNotifications: null,
        webSocketsNewNotification: JSON.parse(localStorage.getItem('newNotification'))
    },
    actions: {
        SET_ACTIVE_MODAL_NOTIFICATIONS({commit}) {
            commit('SET_ACTIVE_MODAL_NOTIFICATIONS');
        },
        SET_INACTIVE_MODAL_NOTIFICATIONS({commit}) {
            commit('SET_INACTIVE_MODAL_NOTIFICATIONS');
        },

        SET_TOGGLE_MODAL_NOTIFICATIONS: ({commit, dispatch}) => {
            dispatch('SET_APPLY_STYLES');
            commit('SET_TOGGLE_MODAL_NOTIFICATIONS');
        },

        SET_APPLY_STYLES({commit}) {
            commit('SET_APPLY_STYLES');
        },

        async SET_NOTIFICATIONS({commit, dispatch}) {
            try {
                const access_token = localStorage.getItem('userAccessToken');
                const user_id = JSON.parse(localStorage.getItem('user')).data.id

                const response = await API({
                    method: 'get',
                    url: `users/${user_id}/notifications?page[number]=1&page[size]=10&sort=-created_at&include=initiator`,
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                })

                // Парсим уведомления
                const parsedNotifications = parseNotifications(response.data)

                commit('SET_NOTIFICATIONS', parsedNotifications);


            } catch (e) {
                console.log(`notifications SET_NOTIFICATIONS ${e}`);
                throw new Error(`notifications SET_NOTIFICATIONS ${e}`)
            }
        },

        async SET_MORE_NOTIFICATIONS({commit, dispatch, getters}) {
            try {
                const access_token = localStorage.getItem('userAccessToken');
                const user_id = JSON.parse(localStorage.getItem('user')).data.id

                const nextPage = getters.GET_NOTIFICATIONS.meta.page['current-page'] + 1;
                const previousData = getters.GET_NOTIFICATIONS.data;

                const response = await API({
                    method: 'get',
                    url: `users/${user_id}/notifications?page[number]=${nextPage}&page[size]=50&sort=-created_at&include=initiator`,
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                })

                // Парсим уведомления
                const parsedNotifications = parseNotifications(response.data, previousData)

                commit('SET_NOTIFICATIONS', parsedNotifications);


            } catch (e) {
                console.log('SET_MORE_NOTIFICATIONS', e);
                throw new Error(`SET_MORE_NOTIFICATIONS, ${e}`)
            }
        },


        async GET_OPPONENT_IN_NOTIFICATION(ctx, opponentData) {
            const response = await API({
                method: 'get',
                url: `/${opponentData.type}?&filter[id]=${opponentData.id}`
            });
            // console.log(response)
            return response.data.data[0];
        },

        DELETE_CERTAIN_NOTIFICATION({commit, getters}, payload) {
            const notifications = getters.GET_NOTIFICATIONS;
            notifications.data = notifications.data.filter(item => item.id !== payload);
            commit('SET_NOTIFICATIONS', notifications);
        },

        SET_WEB_SOCKETS_NEW_NOTIFICATION({commit}, payload) {
            commit('SET_WEB_SOCKETS_NEW_NOTIFICATION', payload)
        }
    },
    mutations: {
        SET_ACTIVE_MODAL_NOTIFICATIONS(state) {
            state.isModalOpened = true
        },
        SET_INACTIVE_MODAL_NOTIFICATIONS(state) {
            state.isModalOpened = false
        },

        SET_TOGGLE_MODAL_NOTIFICATIONS(state) {
            state.isModalOpened = !state.isModalOpened
        },

        SET_APPLY_STYLES(state) {
            state.canApplyStyles = true
        },

        SET_NOTIFICATIONS(state, data) {
            state.notifications = data;
        },

        SET_CLEAR_CURRENT_TEAM_FOR_COMPARE_NOTIFICATIONS(state) {
            state.haveNotificationFromCurrentTeam = false
        },

        SET_TEAMS_FOR_NOTIFICATIONS(state, payload) {
            state.teamsForNotifications = payload
        },

        SET_USERS_FOR_NOTIFICATIONS(state, payload) {
            state.usersForNotifications = payload
        },

        SET_WEB_SOCKETS_NEW_NOTIFICATION(state, payload) {
            state.webSocketsNewNotification = payload
        }
    },
    getters: {
        GET_IS_MODAL_NOTIFICATION_OPENED(state) {
            return state.isModalOpened
        },

        GET_APPLY_STYLES(state) {
            return state.canApplyStyles
        },

        GET_NOTIFICATIONS(state) {
            return state.notifications
        },

        GET_CAN_SET_MORE_NOTIFICATIONS(state) {
            try {
                const currentPage = state.notifications.meta.page['current-page'];
                const lastPage = state.notifications.meta.page['last-page'];

                return currentPage !== lastPage

            } catch (e) {
                return false
            }
        },

        GET_NOTIFICATIONS_FILTERED_BY_DATE(state) {
            try {
                if (!state.notifications) return null;

                const compareFunction = (a, b) => {
                    return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
                };

                const generateObj = (sortedByDateData) => {
                    const newObj = {};

                    for (let dataItem of sortedByDateData) {
                        const date = new Date(dataItem.attributes.createdAt);
                        const keyDate = parseDateForNotifications(date);

                        if (newObj[keyDate]) newObj[keyDate] = [...newObj[keyDate], dataItem]
                        else newObj[keyDate] = [dataItem]
                    }

                    return newObj;
                }

                const data = getNotificationsWithIncludedInfo(state.notifications)

                const sortedByDateData = data.sort((a, b) => compareFunction(a, b));

                return generateObj(sortedByDateData)
            } catch (e) {
                return {};
            }
        },

        GET_NOTIFICATIONS_TEAM_TO_USER(state) {
            return state.notifications.data.filter(item => item.attributes['type_notification'] === 'User.Entries.TeamToUser')
        },

        GET_CURRENT_TEAM_FOR_COMPARE_NOTIFICATIONS(state) {
            return state.haveNotificationFromCurrentTeam
        },

        GET_WEB_SOCKETS_NEW_NOTIFICATIONS(state) {
            return state.webSocketsNewNotification
        }
    }
}
