import {API} from "@/Vuex/axios-config";
import {SPONSOR_ID} from "@/config";
import {isUserHaveActiveTournamentRequest} from "@/otherJS/utils";

// Игры
// 1 - Dota 2
// 2 - FIFA
// 3 - Rugby
// 4 - NHL
// 5 - NBA
// 6 - CS:GO
// 7 - UFC

export default {
    state: {
        upcomingTournaments: null,
        selectedFilter: 'All'
    },

    actions: {
        async SET_UPCOMING_TOURNAMENTS({commit, dispatch, getters}, gameId) {

            // Чтобы получить турниры с открытой регистрацией - нужно сделать filters[index_of_status]=index,
            // где index - индекс статуса турнира. Всего бывают следующие индексы

            //     public const INDEXES_OF_STATUSES = [
            //         self::STATUS_BEFORE_REGISTRATION => 0,
            //         self::STATUS_REGISTRATION        => 1,
            //         self::STATUS_CHECKIN             => 2,
            //         self::STATUS_BEFORE_START        => 3,
            //         self::STATUS_IN_PROCESS          => 4,
            //         self::STATUS_FINISHED            => 5,
            // ];

            // В самом бэкенде необходимо добавить поле для фильтрации index_of_status
            // Как мы видим, турниры с открытой регистрации находятся по индексу 1.

            try {
                const response = await API({
                    method: 'get',
                    url: gameId !== 'All' ?
                        `/tournaments?filter[sponsor_id]=${SPONSOR_ID}&filter[index_of_status]=1&page[number]=1&page[size]=3&sort=-started_at&filter[game_id]=${gameId}&include=background,game`
                        :
                        `/tournaments?filter[sponsor_id]=${SPONSOR_ID}&filter[index_of_status]=1&page[number]=1&page[size]=3&sort=-started_at&include=background,game`
                })

                const responseData = response.data

                responseData.data = responseData.data.map(item => {

                    // Забираю бэкграунд ближайших турниров
                    item.background = responseData.included.find(included =>
                        included.type === 'attachments'
                        && included.attributes.tag === 'background'
                        && String(included.id) === String(item.relationships.background.data.id)
                    ).attributes.url

                    return item
                })

                /*let tournamentsIds = responseData.data.map(el => el.id);
                const allEntries = await dispatch('GET_ALL_TOURNAMENTS_ENTRIES', tournamentsIds);
                responseData.data = isUserHaveActiveTournamentRequest(responseData.data, allEntries, getters.USER)*/

                responseData.data = await dispatch('SET_USER_ACTIVE_ENTRY_IN_TOURNAMENT', {tournaments: responseData.data})

                commit('SET_UPCOMING_TOURNAMENTS', responseData)
            } catch (e) {
                console.log(`SET_UPCOMING_TOURNAMENTS`, e);
                throw new Error('SET_UPCOMING_TOURNAMENTS')
            }
        },

        SET_SELECTED_FILTER({commit}, payload) {
            commit('SET_SELECTED_FILTER', payload)
        }
    },

    mutations: {
        SET_UPCOMING_TOURNAMENTS(state, payload) {
            state.upcomingTournaments = payload
        },

        SET_SELECTED_FILTER(state, payload) {
            state.selectedFilter = payload
        }
    },

    getters: {
        GET_UPCOMING_TOURNAMENTS(state) {
            return state.upcomingTournaments
        },

        GET_SELECTED_FILTER(state) {
            return state.selectedFilter
        }
    }
}