export default class ParticipantService {
    static formattingParticipantStructure(participant) {
        let structuredParticipant = {
            name: null,
            id: null,
            logo: null,
        }
        switch (participant.type) {
            case 'users':
                structuredParticipant = {
                    name: participant.attributes.login,
                    id: participant.id,
                    logo: participant.attributes.logo
                }
                break;
            case 'teams':
                structuredParticipant = {
                    name: participant.attributes.name,
                    id: participant.id,
                    logo: participant.attributes.logo
                }
                break;
            default:
                break;
        }

        return structuredParticipant;
    }
}
