import WinnersService from "@/otherJS/ServiceClasses/WinnersService";


export const getPlayOffDEWinners = (finalMatch, lowerMatch) => {
    let winners = [],
        firstGamers = [],
        secondGamers = [];

    WinnersService.setParticipantDataToArr(finalMatch, firstGamers);
    WinnersService.setParticipantDataToArr(lowerMatch, secondGamers);


    let players = {};
    players.a = firstGamers.find(item => item.winner === true);
    players.b = firstGamers.find(item => item.winner === false);
    players.c = secondGamers.find(item => item.winner === false);

    for (let place in players) {
        players[place].place = place;
        winners.push(players[place])
    }

    winners.sort((a,b) => b.place - a.place)

    return winners
}
