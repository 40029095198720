<template>
  <div class="modal-bg" @click="CLOSE_SHOW_THANKS_ACTION">
    <div class="modal-body">
      <div class="text" :class="getTextColor">{{ getModalText }}</div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import copyGetter from "@/otherJS/VuexHelpers/copyGetter";

export default {
  name: "simple-text-modal",

  methods: {
    ...mapActions([
        'CLOSE_SHOW_THANKS_ACTION'
    ]),
  },
  computed: {
    ...mapGetters([
        'GET_SHOW_THANKS_CONFIG_FOR_SIMPLE_MODAL'
    ]),

    getModalText() {
      const config = copyGetter(this.GET_SHOW_THANKS_CONFIG_FOR_SIMPLE_MODAL);
      return config.text
    },

    getTextColor() {
      const config = copyGetter(this.GET_SHOW_THANKS_CONFIG_FOR_SIMPLE_MODAL);

      switch (config.status) {
        case 'default':
          return 'text_default'
        case 'warning':
          return 'text_warning'
        default:
          return 'text_default'
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-bg {
  z-index: 900;
}

.modal-body {
  background-color: #3A3A3A;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 375px;
  height: 250px;
  max-width: unset;

  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;

    max-width: 187px;
    user-select: none;

    &_default {
      color: var(--tournament-color);
    }

    &_warning {
      color: #FF453A;
    }
  }
}
</style>