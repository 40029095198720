// Функция, которая возвращает текст ошибки. errorText - уникальный текст для ошибки. ...rest - инпуты, в которые забивались сами данные
const generateErrorText = (errorText, ...rest) => {

    for (const value of rest) {
        if (value === '') return 'Пожалуйста, заполните данные!'
    }

    return errorText
}

export default generateErrorText;