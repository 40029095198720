export default {
    state: {
        statusMobileNav: false,
        zoomModalOpened: false
    },

    actions: {
        SET_STATUS_MOBILE_NAV({commit}, data) {
            commit('SET_STATUS_MOBILE_NAV', data);
        },

        TOGGLE_STATUS_MOBILE_NAV({commit}) {
            commit('TOGGLE_STATUS_MOBILE_NAV');
        },

        SET_ACTIVE_STATUS_MOBILE_NAV({commit}) {
            commit('SET_ACTIVE_STATUS_MOBILE_NAV');
        },

        SET_INACTIVE_STATUS_MOBILE_NAV({commit}) {
            commit('SET_INACTIVE_STATUS_MOBILE_NAV');
        },
        TOGGLE_ZOOM_MODAL({commit}) {
            commit('TOGGLE_ZOOM_MODAL')
        }
    },

    mutations: {
        SET_STATUS_MOBILE_NAV(state, data) {
            state.statusMobileNav = data;
        },

        TOGGLE_STATUS_MOBILE_NAV(state) {
            state.statusMobileNav = !state.statusMobileNav;
        },

        SET_ACTIVE_STATUS_MOBILE_NAV(state) {
            state.statusMobileNav = true
        },

        SET_INACTIVE_STATUS_MOBILE_NAV(state) {
            state.statusMobileNav = false
        },
        TOGGLE_ZOOM_MODAL(state) {
            state.zoomModalOpened = !state.zoomModalOpened
        }
    },

    getters: {
        GET_STATUS_MOBILE_NAV(state) {
            return state.statusMobileNav
        },
        GET_ZOOM_MODAL_STATUS(state) {
            return state.zoomModalOpened
        }
    }
}