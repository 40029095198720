<template>
  <div class="wrapper">
    <div class="modal-header">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="GO_BACK">
        <path
            d="M32 28C32 30.2091 30.2091 32 28 32L4 32C1.79086 32 0 30.2091 0 28L0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28Z"
        />
        <path d="M19 9L12 16L19 23" stroke="#B0B0B0" stroke-width="1.5" stroke-linejoin="round"/>
        <path
            d="M28 31L4 31V33H28V31ZM1 28L1 4H-1V28L1 28ZM4 1L28 1V-1H4V1ZM31 4L31 28H33V4H31ZM28 1C29.6569 1 31 2.34315 31 4H33C33 1.23858 30.7614 -1 28 -1V1ZM1 4C1 2.34315 2.34315 1 4 1V-1C1.23858 -1 -1 1.23858 -1 4H1ZM4 31C2.34315 31 1 29.6569 1 28L-1 28C-1 30.7614 1.23858 33 4 33V31ZM28 33C30.7614 33 33 30.7614 33 28H31C31 29.6569 29.6569 31 28 31V33Z"
            fill="#B0B0B0"/>
      </svg>
      <span>Восстановление пароля</span>
    </div>
    <div class="modal-body">
      <div class="label">
        <span class="s1">Телефон или email</span>
      </div>
      <div class="modal-input">
        <input ref="input" type="text" autocomplete="on" placeholder="Например +79856541235" required>
        <div v-if="AUTH.errorText !== ''" class="error" v-html="AUTH.errorText"></div>
      </div>

      <div class="btn__block">
        <button v-if="!AUTH.isRequest" class="filled__yellow" @click="recover">Восстановить пароль</button>
        <spinner v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Spinner from "@/components/UIElements/spinner";
import {isPhoneOrMail} from "@/otherJS/testMailOrPhoneRegExp";

export default {
  name: "login-recover",
  components: {Spinner},
  computed: {
    ...mapGetters([
      'AUTH'
    ])
  },
  methods: {
    ...mapActions([
      'RECOVER_PASSWORD',
      'GO_BACK'
    ]),
    recover() {
      let recoverData = {
        inputValue: this.$refs.input.value.toLowerCase(),
        type: isPhoneOrMail(this.$refs.input.value)
      }
      this.RECOVER_PASSWORD(recoverData);
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 210px;
}
</style>
