<template>
  <div
      class="rect skelet-anim"
      :style="{
        height: height + 'px',
        maxWidth: width + 'px',
        width: '100%',
        marginBottom: marginBottom + 'px'
      }"
  ></div>
</template>

<script>
export default {
  name: "skelet-rectangle",
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    marginBottom: {
      type: Number,
      default: 15
    }
  }
}
</script>

<style scoped lang="scss">
  .rect {
    background-color: darken(#3A3A3A, 3%);
  }
</style>