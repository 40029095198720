export const SPONSOR_ID = process.env.VUE_APP_SPONSOR_ID;

export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

export const SITE_IS_OPEN = process.env.VUE_APP_SITE_IS_OPEN;

export const PUSHER_APP_KEY = process.env.VUE_APP_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.VUE_APP_PUSHER_APP_CLUSTER;

export const SEO_TITLE = process.env.VUE_APP_SEO_TITLE;
export const SEO_KEYWORDS = process.env.VUE_APP_SEO_KEYWORDS;