import {attachmentsGetFromInclude, gameGetFromInclude} from "@/otherJS/include-filtering";

const validateEventGroupTournaments = (response) => {

    const included = response.included;

    response.data = response.data.map(item => {
        item.background = attachmentsGetFromInclude(item, included, 'background').attributes.url;
        item.game = gameGetFromInclude(item, included);

        return item
    });

    return response;
}

export default validateEventGroupTournaments