// import {API} from "@/Vuex/axios-config";
import setRatingTournamentGroups from "@/otherJS/setRatingTournamentGroups";

export default {
    state: {
        selectedFilterRating: null,
        mainFilterRatings: null
    },

    actions: {
        async SET_RATING_MAIN_TOURNAMENT_GROUPS({commit}, tournamentGroupsId) {

            const {_, rating} = await setRatingTournamentGroups(tournamentGroupsId)

            commit('SET_RATING_MAIN_TOURNAMENT_GROUPS', rating)
        },
        
        SET_SELECTED_FILTER_RATING({commit}, payload) {
            commit('SET_SELECTED_FILTER_RATING', payload)
        }
    },

    mutations: {
        SET_SELECTED_FILTER_RATING(state, payload) {
            state.selectedFilterRating = payload
        },

        SET_RATING_MAIN_TOURNAMENT_GROUPS(state, payload) {
            state.mainFilterRatings = payload
        },

    },

    getters: {
        GET_SELECTED_FILTER_RATING(state) {
            return state.selectedFilterRating
        },

        GET_RATING_MAIN_TOURNAMENT_GROUPS(state) {
            try {
                // сортирую массив по рейтингу
                const sortedArray = state.mainFilterRatings.sort((a, b) => Number(b.rating) - Number(a.rating));

                // Возвращаю массив с добавленным полем "place"
                return sortedArray.map((item, index) => {
                    item.place = String(index + 1)
                    return item
                })
            } catch (e) {
                return []
            }

        }
    }
}