import {API} from "@/Vuex/axios-config";
import {SPONSOR_ID} from "@/config";
import {attachmentsGetFromInclude} from "@/otherJS/include-filtering";

export default {
    state: {
        tournamentGroups: null,
    },

    actions: {
        async SET_TOURNAMENT_GROUPS({commit, getters, dispatch}) {
            try {
                const response = await API({
                    method: 'get',
                    url: `/groups-of-tournaments?filter[sponsor_id]=${SPONSOR_ID}&include=preview,logo,game,background&sort=index,id`
                })

                const tournamentGroups = response.data;

                tournamentGroups.data = tournamentGroups.data.map((item) => {

                    // Задаю превью с лого
                    // Суть в том, что иногда нельзя в included получить вместе preview и logo
                    // поэтому надо будет сделать следующую проверку
                    item.preview = attachmentsGetFromInclude(item, tournamentGroups.included, 'preview')?.attributes?.url ?? ''
                    item.logo = attachmentsGetFromInclude(item, tournamentGroups.included, 'logo')?.attributes?.url ?? ''
                    item.background_url = attachmentsGetFromInclude(item, tournamentGroups.included, 'background')?.attributes?.url ?? ''

                    // Для удобства сразу забиваю инфу об игровой дисциплине
                    item.gameInfo = tournamentGroups.included.find((game) => game.type === 'games' &&
                        String(game.id) === String(item.relationships.game.data.id))

                    return item;
                })



                commit('SET_TOURNAMENT_GROUPS', tournamentGroups)
            } catch (e) {
                console.log('SET_TOURNAMENT_GROUPS', e);
                throw new Error(`SET_TOURNAMENT_GROUPS ${e}`)
            }
        }
    },

    mutations: {
        SET_TOURNAMENT_GROUPS(state, payload) {
            state.tournamentGroups = payload;
        }
    },

    getters: {
        GET_TOURNAMENT_GROUPS(state) {
            if (!state.tournamentGroups) {
                return []
            }

            return state.tournamentGroups.data
        }
    }
}
